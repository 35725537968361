import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import { useContext } from "react";

const DbBackup = () => {
  const { authState } = useContext(GlobalContext);
  const config = {
    headers: {
      token: authState.access_token,
    },
  };
  const backData = {
    language: "ENGLISH",
  };
  const backUpDb = async () => {
    try {
      let apiRes = await henceforthApi.Common.backupDb(backData, config);
      console.log("apiRes", apiRes);
      let data = apiRes.data;
      let base_url = data.base_url;

      const origin = window.location.origin;
      //
      const openUrl = `${base_url}`.replace(origin, "");
      console.log("loc", openUrl);
      window.open(`${openUrl}`);
    } catch (error) {
      console.log("Error", error);
      toast.error(error.response.body.error_description);
    }
  };

  return (
    <div className="animated fadeInRight">
      <div className="ibox">
        <div className="ibox-content">
          <div className="mail-box-header text-center">
            <h2>DB BACKUP</h2>
          </div>
          <div className="ibox-content text-center">
            <p>
              " If you click on Download, We will share Db Backup On Below
              provided emails as an Attachment"{" "}
            </p>
            <div className="text-secondary text-underline cursor-pointer">
              Db MySql file:{" "}
              <span className="ms-2 text-primary" onClick={() => backUpDb()}>
                Download
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DbBackup;
