import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Login from "./activity/Login";
import ForgotPassword from "./activity/forgotPassword";
import MainActivity from "./activity/MainActivity";
import { GlobalContext } from "./context/Provider";
import { useContext } from "react";
import { ToastContainer } from "react-toastify";
import { GlobalLoading } from "./components/BootstrapCompo";
import henceforthApi from "./utils/henceforthApi";

export default () => {
  let navigate = useNavigate();
  let location = useLocation();

  const { authState, loading } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);

  return (
    <>
      {authState.access_token ? (
        <Routes>
          <Route path="/" element={<MainActivity />}>
            <Route path="/" />
            <Route path="/policies" />
            <Route path="/policies/add" />
            <Route path="/policies/:type" />
            <Route path="/policies/:type/edit" />
            <Route path="/contact-us/:page" />
            <Route path="/reports/nft/:page" />
            <Route path="/reports/users/:page" />
            <Route path="/notification" />
            <Route path="/profile" />
            <Route path="/profile/edit" />
            <Route path="/staffs/:page/:role" />
            <Route path="/staff/:id" />
            <Route path="/staff/edit/:id" />
            <Route path="/staff/add" />
            <Route path="/email-template" />
            <Route path="/add-template" />
            <Route path="/edit-template" />
            <Route path="/change-password" />
            <Route path="/users/:page" />
            <Route path="/orders/:page" />
            <Route path="/user/:id" />
            <Route path="/products/:page" />
            <Route path="/product/:id" />
            <Route path="/language" />
            <Route path="/faq/:page" />
            <Route path="/faq/edit/:id" />
            <Route path="/faq/add" />
            <Route path="/language/edit/:id" />
            <Route path="/db-backup" />
            <Route path="/weekly-section/:type/:page" />
            <Route path="/weekly-section/:type/edit/:page" />
            <Route path="/creators/:page" />
            <Route path="/creator/:id" />
            <Route path="/creator/:id/photos/public/:page" />
            <Route path="/creator/:id/photos/private/:page" />
            <Route path="/tiktok-approval/:page" />
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/forgot-password"
            element={<ForgotPassword navigate={navigate} location={location} />}
          />
          <Route
            path="*"
            element={<Login navigate={navigate} location={location} />}
          />
        </Routes>
      )}
      {loading ? <GlobalLoading /> : ""}
      <ToastContainer autoClose={2000} />
    </>
  );
};
