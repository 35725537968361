import { Link, useMatch } from "react-router-dom";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import moment from "moment";

export default () => {
  const match = useMatch("/policies/:type");

  const { authState, authDispatch } = useContext(GlobalContext);
  const [state, setState] = useState({
    created_at: "",
    description: "",
    image_url: "",
    type: "",
    updated_at: "",
    _id: "",
  });
  henceforthApi.setToken(authState.access_token);

  const initialiseData = async () => {
    try {
      let apiRes = await henceforthApi.Policies.byType(match.params.type);
      let obj =
        Array.isArray(apiRes.data.data) && apiRes.data.data.length
          ? apiRes.data.data[0]
          : apiRes.data.data;
      setState({
        ...state,
        ...obj,
      });

      console.log("image_url", obj);
    } catch (error) {}
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let apiRes = await henceforthApi.Policies.delete(state._id);
          Swal.fire({
            title: "Policy Deleted",
            text: "",
            icon: "success",
            confirmButtonText: "OK",
          });
          window.history.back();
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: "",
            icon: "error",
            confirmButtonText: "Cancel",
          });
        }
      }
    });
  };

  useEffect(() => {
    initialiseData();
  }, []);

  // if(!state.image_url){
  //   return <h1>no data</h1>
  // }

  return (
    <div className="animated fadeInRight">
      <div className="ibox">
        <div className="ibox-title">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="flex-grow-1 text-center text-uppercase">
              {state.title}
            </h3>
            <div className="">
              <Link to="/policies" className="btn btn-primary-button me-3 px-4">
                <i class="fas fa-arrow-left me-1"></i>Back
              </Link>
              <Link
                className="btn btn-primary-button  me-3 px-4"
                to={`/policies/${match.params.type}/edit`}
              >
                <i class="fas fa-edit me-1"></i>Edit
              </Link>
              {/* <button onClick={handleDelete} className="btn btn-danger ">
                <i className="fas fa-trash me-2"></i>delete
              </button> */}
            </div>
          </div>
        </div>
        <div className="ibox-content">
          <div className="py-4">
            {/* {console.log("image", state.image_url)} */}

            {/* <img src={state.image_url ? state.image_url : About} alt="" className="detail-page-image" /> */}
            <img
              src={
                state.image_url
                  ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${state.image_url}`
                  : ''
              }
              alt=""
              className="detail-page-image"
            />
          </div>
          <p className="text-end ">
            <b>
              Last Updated:
              {moment(Number(state.created_at)).format("MMM DD, YYYY")}
            </b>
          </p>
          <div
            className="word-break-policies-detail"
            dangerouslySetInnerHTML={{ __html: state.description }}
          ></div>

          <div className="text-end"></div>
        </div>
      </div>
    </div>
  );
};
