import React, { useState, useEffect, useContext } from "react";
import { EditorState } from "draft-js";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ChipButton } from "../components/BootstrapCompo";
import { toast } from "react-toastify";

const ReactQuill =
  typeof window === "object" ? require("react-quill") : () => false;

const ALL_USERS = "ALL_USERS";
const SELECTED_USERS = "SELECTED_USERS";
const EMAIL = "EMAIL";
const PUSH = "PUSH";

const Notification = () => {
  const navigate = useNavigate();
  const { authState, authDispatch, setLoading } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);
  const [description, setDesciption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [subject, setSubject] = useState("");
  const [state, setState] = useState({
    type: EMAIL,
    send_to: ALL_USERS,
    user_ids: [],
    list_user: [],
    find_user: [],
    selectedUser: new Map(),
  });

  const radioHandler = (send_to) => {
    setState({
      ...state,
      send_to,
    });
  };
  const radioHandlerType = (type) => {
    setState({
      ...state,
      type,
    });
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (!subject) {
      return toast.warn("Please enter subject");
    }
    const items = {
      type: state.type,
      send_to: state.send_to,
      user_ids: state.user_ids,
      subject: subject,
      message: description,
      language: "ENGLISH",
    };

    henceforthApi.Administrator.sendNotifications(items)
      .then((res) => {
        Swal.fire({
          title: "Notification Sent",
          text: res.message,
          icon: "success",
          confirmButtonText: "OK",
        });
        setDesciption("");
        setSubject("");
        setState({
          ...state,
          user_ids: state.user_ids,
          list_user: [],
        });
      })
      
      .catch((err) => {
        Swal.fire({
          title: "Error!",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
  }

  const handleFIlter = async (value) => {
    setInputValue(value);
    if (value) {
      // setLoading(true)
      try {
        let apiRes = await henceforthApi.Common.allUsers(value);
        setState({
          ...state,
          find_user: apiRes.data.data,
        });
        setLoading(false);
      } catch (error) {
        setState({
          ...state,
          find_user: [],
        });
        setLoading(false);
      }
    }
  };

  const onClickChip = (_id, name) => {
    const { selectedUser } = state;
    if (selectedUser.has(_id)) {
      selectedUser.delete(_id);
    } else {
      selectedUser.set(_id, name);
    }
    let rowDataId = [];
    let rowData = [];
    selectedUser.forEach((res, key) => {
      console.log(res, key);
      rowDataId.push(key);
      rowData.push({ _id: key, name: res });
    });
    setState({
      ...state,
      list_user: rowData,
      selectedUser,
      user_ids: rowDataId,
      find_user: [],
    });
    setInputValue("");
  };

 

  const onEditorStateChange = (e) => {
    console.log(e);
    setDesciption(e);
  };

  return (
    <div className="row animated fadeInRight">
      <div className="col-md-12">
        <div className="ibox">
          <div className="ibox-title">Notification</div>
          <div className="ibox-content">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="mb-2 d-block">Select users by Email</label>
                <div className="d-flex align-items-center">
                  <div
                    onClick={(e) => radioHandler(ALL_USERS)}
                    className="radio-btn-notif d-flex align-items-center me-3"
                  >
                    <input
                      type="radio"
                      name="user"
                      className="me-2"
                      checked={state.send_to === ALL_USERS}
                    />
                    <label for="all">All Users</label>
                  </div>
                  <div
                    onClick={(e) => radioHandler(SELECTED_USERS)}
                    className=" radio-btn-notif d-flex align-items-center"
                  >
                    <input
                      type="radio"
                      name="user"
                      className="me-2"
                      checked={state.send_to === SELECTED_USERS}
                    />
                    <label for="selected">Only Selected Users</label>
                  </div>
                </div>
                {state.send_to === SELECTED_USERS ? (
                  <>
                    <div className="mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select user by email"
                        value={inputValue}
                        onChange={(e) => handleFIlter(e.target.value)}
                      />
                      <ul class="list-group">
                        {state.find_user.map((res) => {
                          return (
                            <li
                              class="list-group-item"
                              onClick={() =>
                                onClickChip(res._id, `${res.email}`)
                              }
                            >
                              {res.email}{" "}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {state.list_user.length ? (
                      <>
                        <hr />
                        <div className="form-group">
                          <label className="mb-2">Selected User</label>
                          <div className=" d-flex ">
                            {state.list_user.map((res) => {
                              return (
                                <ChipButton
                                  key={res._id}
                                  {...res}
                                  onClick={onClickChip}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group">
                <label className="mb-2">Subject</label>
                <input
                  name="subject"
                  type="text"
                  className="form-control"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="mb-2 d-block">Select users by type</label>
                <div className="d-flex align-items-center">
                  <div
                    onClick={(e) => radioHandlerType(EMAIL)}
                    className="radio-btn-notif d-flex align-items-center me-3"
                  >
                    <input
                      type="radio"
                      name="type"
                      className="me-2"
                      checked={state.type === EMAIL}
                      EMAIL
                    />
                    <label for="all">email</label>
                  </div>
                  <div
                    onClick={(e) => radioHandlerType(PUSH)}
                    className=" radio-btn-notif d-flex align-items-center"
                  >
                    <input
                      type="radio"
                      name="type"
                      className="me-2"
                      checked={state.type === PUSH}
                    />
                    <label for="selected">push</label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  border: "1px solid rgba(0,0,0,.2)",
                  paddingBottom: "20px",
                  background: "white",
                  marginBottom: "20px",
                  maxHeight: "200px",
                }}
              >
                <ReactQuill
                  theme="snow"
                  onChange={(e) => onEditorStateChange(e)}
                  placeholder={`Write your content`}
                  value={description}
                />
              </div>
              <div className="form-group">
                {/* <button onClick={handleCancel} type="button" className="btn btn-white btn-sm me-2">Cancel</button> */}
                <button
                  type="submit"
                  className="btn btn-primary-button btn-sm"
                  disabled={
                    state.send_to === SELECTED_USERS &&
                    state.user_ids.length === 0
                  }
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
