import React, { createContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ROLES_BACKUP, ROLES_CONTACT_US, ROLES_CREATOR, ROLES_DASHBOARD, ROLES_FAQS, ROLES_NFTS, ROLES_NOTIFICATION, ROLES_POLICIES, ROLES_SALES, ROLES_STAFF_MEMBERS, ROLES_USERS } from '../constants/actionTypes';
import { SOLD, ON_SALE, ALL} from '../constants/actionTypes';
import henceforthApi from '../utils/henceforthApi';
import logoutSuccess from './actions/auth/logoutSuccess';
import authInitialState from './initialStates/authInitialState';
import authInitialStaticData from './initialStates/authInitialStaticData';
import auth from './reducers/auth';
import pagination from './reducers/pagination';
import static_data from './reducers/static_data';

export const GlobalContext = createContext({});

function GlobalProvider({ children }) {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [currentUsd, setCurrenctUsd] = useState(0)

    const [authState, authDispatch] = useReducer(auth, authInitialState, () => {
        const localAuthState = localStorage.getItem("authState");
        return localAuthState ? JSON.parse(localAuthState) : authInitialState
    })

    const [staticDataState, staticDataDispatch] = useReducer(static_data, authInitialStaticData, () => {
        const localStaticDataState = localStorage.getItem("staticDataState");
        return localStaticDataState ? JSON.parse(localStaticDataState) : authInitialStaticData
    })



    const scrollToTop = () => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }

    const logOutNow = () => {


        logoutSuccess({})(authDispatch);
        logoutSuccess(authInitialStaticData)(staticDataDispatch);
        navigate("/", { replace: true });

    };

    const filterNfts = [
        SOLD,
        ON_SALE,
        ALL,

    ]


    const staffMembers = [
        // ROLES_STAFF_MEMBERS,
        ROLES_DASHBOARD,
        ROLES_USERS,
        ROLES_CREATOR,
        ROLES_NFTS,
        ROLES_SALES,
        ROLES_FAQS,
        ROLES_POLICIES,
        ROLES_CONTACT_US,
        ROLES_NOTIFICATION,
        ROLES_BACKUP,
    ]

    useEffect(() => {
        localStorage.setItem("authState", JSON.stringify(authState))
    }, [authState]);

    useEffect(() => {
        localStorage.setItem("staticDataState", JSON.stringify(staticDataState))
    }, [staticDataState]);

    // useEffect(() => {
    //     localStorage.setItem("pagination", JSON.stringify(paginationState))
    // }, [paginationState]);

    const initialiseFirstTime = async () => {
        let ethusd = await henceforthApi.Common.ethusd()
        setCurrenctUsd(ethusd.body.USD)
    }
    useEffect(() => {
        initialiseFirstTime()
    }, [])

    return (
        <GlobalContext.Provider
            value={{
                currentUsd,
                authState,
                filterNfts,
                staffMembers,
                authDispatch,
                scrollToTop,
                loading,
                setLoading,
                staticDataState,
                staticDataDispatch,
                logOutNow
            }}
        >
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider
