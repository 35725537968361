

import { Link } from "react-router-dom";
import ProfileDropdown from "./profileDropdown";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from "../../../context/Provider";
import { ROLES_BACKUP, ROLES_CONTACT_US, ROLES_CREATOR, ROLES_DASHBOARD, ROLES_FAQS, ROLES_NFTS, ROLES_NOTIFICATION, ROLES_POLICIES, ROLES_REPORTS, ROLES_SALES, ROLES_USERS } from "../../../constants/actionTypes";

const MenuItems = [
  // { name: "Language", path: "/language", icon: "fas fa-language" },
  { name: "FAQ", path: "/FAQ/1", icon: "fas fa-question-circle", role: ROLES_FAQS },
  { name: "Content", path: "/policies", icon: "fas fa-folder", role: ROLES_POLICIES },
  { name: "Contact-Us", path: "/contact-us/1", icon: "fas fa-address-book", role: ROLES_CONTACT_US },
  // { name: "NFTs Reports", path: "/reports/nft/1", icon: "fas fa-address-book", role: ROLES_REPORTS },
  // { name: "Users Reports", path: "/reports/users/1", icon: "fas fa-address-book", role: ROLES_REPORTS },
  { name: "Notification", path: "/notification", icon: "fas fa-bell", role: ROLES_NOTIFICATION },
  // { name: "Email Templates", path: "/email-template", icon: "fa fa-th-large" },
  { name: "DB Backup", path: "/db-backup", icon: "fas fa-hdd", role: ROLES_BACKUP },
];

export default (props) => {
  const { authState } = useContext(GlobalContext);
  const roles = (Array.isArray(authState.roles) ? authState.roles : [])

  let location = useLocation();

  return (
    <nav
      class={`navbar-default navbar-static-side ${props.isVisible === true ? "open-nav" : ""}`}
      role="navigation"
    >
      <div class="sidebar-collapse">
        <div className="arrow d-md-none" onClick={props.toggle}>
          <i class="fa fa-times"></i>
        </div>
        <ul class="nav" id="side-menu">
          <li class="nav-header  text-center">
            <ProfileDropdown logOutNow={props.logOutNow} roles={roles} />
            <div class="logo-element">IN+</div>
          </li>
          {(roles.includes(ROLES_DASHBOARD) || authState.super_admin) ?
            <li className={location.pathname === ("/") ? "active" : ""}>
              <Link to="/">
                <i class="fa fa-th-large"></i> <span class="nav-label">Dashboards</span>
              </Link>
            </li> : ''}
          {(roles.includes(ROLES_USERS) || authState.super_admin) ?
            <li className={location.pathname.includes("/users") ? "active" : ""}>
              <Link to="/users/1">
                <i class="fas fa-user"></i> <span class="nav-label">Users</span>
              </Link>
            </li> : ''}
          {/* {(roles.includes(ROLES_CREATOR) || authState.super_admin) ?
            <li className={location.pathname.includes("/creators") ? "active" : ""}>
              <Link to="/creators/1">
                <i class="fas fa-user-tie"></i> <span class="nav-label">Creators</span>
              </Link>
            </li> : ''} */}
          {(roles.includes(ROLES_NFTS) || authState.super_admin) ?
            <li className={location.pathname.includes("/products") ? "active" : ""}>
              <Link to="/products/1">
                <i class="fas fa-folder"></i> <span class="nav-label">Products</span>
              </Link>
            </li> : ''}
          {(roles.includes(ROLES_SALES) || authState.super_admin) ?
            <li className={location.pathname.includes("/orders") ? "active" : ""}>
              <Link to="/orders/1">
                <i class="fas fa-folder"></i> <span class="nav-label">Orders</span>
              </Link>
            </li> : ''}
          {/* ${location.pathname.includes("weekly-section") ? "active" : ""} */}
          <aside id="sidebar" className="sidebar w-100">
            <ul className={`sidebar-nav `} id="sidebar-nav">
              <li className={`nav-item dropdown-list`} >
                <span className="nav-link nav-label collapsed" data-bs-target="#chartss-nav" data-bs-toggle="collapse" href="#">
                  <i className="fas fa-folder mx-2"></i><span className="nav-label"> Weekly Selection</span> <i className="fas fa-chevron-down bi-chevron-down  mx-2 my-3"></i>
                </span>
                <ul id="chartss-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
                  <li className={location.pathname.includes("/weekly-section/social") ? "active" : ""}>
                    <Link to="/weekly-section/social/1" className="staminggg sidebar-text">
                      <i className="fas fa-hdd me-2"></i> <span className="nav-label">Social</span>
                    </Link>
                  </li>
                  <li className={location.pathname.includes("/weekly-section/marketplace") ? "active" : ""}>
                    <Link to="/weekly-section/marketplace/1" className="staminggg sidebar-text">
                      <i className="fas fa-hdd me-2"></i> <span className="nav-label">Market Place</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </aside>

          {(roles.includes(ROLES_SALES) || authState.super_admin) ?
            <li className={location.pathname.includes("/tiktok-approval") ? "active" : ""}>
              <Link to="/tiktok-approval/1">
                <i class="fas fa-check-square"></i> <span class="nav-label">Tiktok Approval</span>
              </Link>
            </li> : ''}

          {MenuItems.map((res) => {
            return (roles.includes(res.role) || authState.super_admin) ?
              < li className={location.pathname.includes() ? "active" : ""} >
                <Link to={res.path}>
                  <i class={res.icon}></i> <span class="nav-label">{res.name}</span>
                </Link>
              </li> : ''
          })}
        </ul>
      </div>
    </nav >
  );
}