import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import staticDataNftsSuccess from "../context/actions/auth/staticDataNftsSuccess";
import { ExploreCard } from "../components/ExploreComp";
import PaginationLayout from "../components/PaginationLayout";

import moment from "moment";
import { useParams } from "react-router-dom";

export default () => {
  const location = useLocation();
  const match = useMatch("/products/:page");
  const navigate = useNavigate();
  const newParam = new URLSearchParams(location.search);
  const params = useParams();
  let limit = 9;
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [start_date, setStartDate] = useState(0);
  const [end_date, setEndDate] = useState(0);

  const {
    authState,
    staticDataState,
    currentUsd,
    loading,
    staticDataDispatch,
    scrollToTop,
    filterNfts,
  } = useContext(GlobalContext);
  const nfts = staticDataState.nfts;

  henceforthApi.setToken(authState.access_token);

  const onSearchNft = (nftSearch) => {
    const newSearchparams = new URLSearchParams();
    if (nftSearch) {
      newSearchparams.set("search", nftSearch);
    }
    navigate(`/products/1?${newSearchparams.toString()}`);
  };
  const onClearSearch = () => {
    const newSearchparams = new URLSearchParams();

    navigate({ search: newSearchparams.toString() });
  };

  const downloadCsv = async () => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      let apiRes = await henceforthApi.Nfts.export(
        moment(start_date).format("DD/MM/YYYY"),
        moment(end_date).format("DD/MM/YYYY"),
        timezone
      );
      let data = apiRes.data.data;
      console.log(data);

      const rows = [
        [
          "Id",
          "Created_by",
          "Current Owner",
          "description",
          "start_date",
          "end_date",
          "is_blocked",
          "is_deleted",
          "is_sold",
          "min_bid",
          "name",
          "price",
          "royalties",
          "type",
          "unlockable",
          "video_url",
        ],
      ];
      if (Array.isArray(data)) {
        data.map((res) => {
          rows.push([
            res._id,
            `${res.created_by.first_name} ${res.created_by.last_name}`,
            `${res.current_owner.first_name} ${res.current_owner.last_name}`,
            res.description,
            res.start_date,
            res.end_date,
            res.is_blocked,
            res.is_sold,
            res.min_bid,
            res.name,
            res.price,
            res.royalties,
            res.type,
            res.unlockable,
            res.video_url,
          ]);
        });
      }
      console.log(rows);

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `nfts_${moment().valueOf()}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
      let closeModal = document.getElementById("closeModal");
      if (closeModal) {
        closeModal.click();
      }
    } catch (error) {}
  };

  const onChangePagination = (newVal) => {
    const newParam = new URLSearchParams(location.search);
    if (newParam.has("search")) {
      navigate(`/products/${newVal}?search=${newParam.get("search")}`);
    } else {
      navigate(`/products/${newVal}`);
    }
  };
  useEffect(() => {
    const initialiseNfts = async () => {
      let searchData = newParam.has("search") ? newParam.get("search") : "";
      setSearch(searchData);
      try {
        let apiRes = await henceforthApi.Nfts.pagination(
          Number(params.page) - 1,
          newParam.get("search")
        );
        staticDataNftsSuccess(apiRes.data)(staticDataDispatch);
        console.log("apiRes", apiRes);
        setTotalCount(apiRes.data.total_count);
        setPageData(apiRes.data.data);
      } catch (error) {
        console.log("error", error);
        setPageData([]);
        setTotalCount(0);
      }
    };
    scrollToTop();
    initialiseNfts();
  }, [params.page, newParam.get("search")]);

  const onChangeRole = (role) => {
    if (newParam.has("search")) {
      navigate(`/products/1/${role}?search=${newParam.get("search")}`);
    } else {
      navigate(`/products/1/${role}`);
    }
  };
  return (
    <>
      <div class="row animated fadeInRight">
        <div class="col-md-12">
          <div className="ibox">
            <div className="ibox-title d-flex align-items-center justify-content-between">
              <h5>Products</h5>
              <div class="dropdown">
               
                <ul class="dropdown-menu" aria-labelledby="staffDropdown">
                  {filterNfts.map((res) => {
                    return (
                      <li onClick={() => onChangeRole(res)}>
                        <a class="dropdown-item" href="#">
                          {String(res).replace("_", " ")}
                        </a>
                      </li>
                    );
                  })}
                  {match.params.role !== "all" ? (
                    <li onClick={() => onChangeRole("all")}>
                      <a class="dropdown-item" href="#">
                        Reset
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              <button
                className="btn btn-primary-button mb-1"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Export
              </button>
            </div>
            <div className="ibox-content">
              <form
                className="row"
                onSubmit={(e) => {
                  e.preventDefault();
                  onSearchNft();
                }}
              >
                <div className="col-md-12">
                  <div class="input-group form-group">
                    <input
                      placeholder="Search by Name"
                      type="text"
                      class="form-control form-control-sm"
                      value={
                        newParam.has("search") ? newParam.get("search") : ""
                      }
                      onChange={(e) => {
                        onSearchNft(e.target.value);
                      }}
                    />
                    <span class="input-group-append">
                      <button
                        type="submit"
                        class="btn btn-sm btn-primary-button h-100"
                      >
                        Go!
                      </button>
                    </span>
                  </div>
                </div>
              </form>

              <div className="row">
                {nfts.data.map((res) => {
                  return (
                    <ExploreCard
                      key={res._id}
                      {...res}
                      currentUsd={currentUsd}
                      currentUser={authState._id}
                    />
                  );
                })}
              </div>

             
              <PaginationLayout
                count={totalCount}
                data={pageData}
                page={Number(params.page)}
                limit={Number(limit)}
                // loading={loading}
                onPageChange={(val) => onChangePagination(val)}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalLabel">
                Download CSV
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModal"
              ></button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label">Date From</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setStartDate(e.target.valueAsNumber)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Date To</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setEndDate(e.target.valueAsNumber)}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer ">
              <button
                type="button"
                class="btn btn-primary-button"
                onClick={() => downloadCsv()}
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
