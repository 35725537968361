import moment from "moment";
import { Link } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";
import henceforthIPFS from "../utils/henceforthIPFS";
import { Spinner } from "./BootstrapCompo";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import verify from "../assets/images/verified.svg";







export const ExploreCard = ({
  currentUsd,
  _id,
  created_at,
  created_by,
  current_owner,
  file_ext,
  file_url,
  name,
  price,
}) => {
  return (
    <div class="col-lg-4 col-md-6">
      <div class="row">
        <div class="col">
          <div class=" mb-4 product-box">
            <Link to={`/product/${_id}?type=weekly`}>
              <div className="explore-img">
                {String(file_ext).includes("video") ? (
                  <video autoPlay muted loop>
                    <source src={henceforthApi.FILES.video(file_url)}></source>
                  </video>
                ) : (
                  <img src={henceforthApi.FILES.imageOriginal(file_url)} />
                )}
              </div>
            </Link>
            <div class="card-body product-content">
              <h2 className="fw-bold mt-0 mb-0 text-truncate">{name}</h2>

              <div className="both-added my-2">
                <div className="d-flex align-items-center">
                  <div className="brown-img me-2">
                    {current_owner ? (
                      <img
                        src={
                          current_owner.profile_pic
                            ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${current_owner.profile_pic}`
                            : profile_placeholder
                        }
                      />
                    ) : (
                      <img
                        src={
                          created_by?.profile_pic
                            ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${created_by?.profile_pic}`
                            : profile_placeholder
                        }
                      />
                    )}
                  </div>
                  <div className="">
                    <h3 className="fw-bold mb-0">
                      {current_owner ? current_owner?.name : created_by?.name}{" "}
                      {current_owner
                        ? current_owner?.last_name
                        : created_by?.last_name}
                    </h3>
                    <p className="mb-0 text-gray">
                      @
                      {current_owner
                        ? current_owner?.user_name
                          ? current_owner?.user_name
                          : "Not available"
                        : created_by?.user_name
                          ? created_by?.user_name
                          : "Not available"}
                    </p>
                  </div>
                </div>
                <div className="brown-text text-wrap">
                  <h2>{moment(Number(created_at)).format("MMM DD, YYYY")}</h2>
                  <p>Minted</p>
                </div>

              </div>


              <hr className="my-2 mb-1" />
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <div className="bown-num ">
                    <h4 className="mb-0">{price} ELEN</h4>
                    <h5 className="mb-0">
                      ${Number(currentUsd * Number(price)).toFixed(3)} USD
                    </h5>
                  </div>

                </div>
                <Link
                  to={`/product/${_id}?type=weekly`}
                  className="btn btn-primary-button"
                >
                  Details
                  <i class="fa fa-long-arrow-right ms-1"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const TopCreateCard = ({
  _id,
  first_name,
  last_name,
  profile_pic,
  total_coins,
  user_name,
}) => {
  return (
    <Link to={`/creator/${_id}`}>
      <div className="box-up">
        <div className="boxess">
          <div className="brown-img">
            <img
              src={
                profile_pic
                  ? `${henceforthApi.API_FILE_ROOT_SMALL}${profile_pic}`
                  : profile_placeholder
              }
            />
          </div>
          <div className="both-added">
            <div className="brown-text">
              <h2>
                {first_name ? first_name : user_name}{" "}
                {last_name ? last_name : ""}
              </h2>
              <p>5841.25 ELEN</p>
            </div>
            <div className="bown-num">
              <h4>{total_coins}</h4>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};




export const Activity = ({
  nft_id,
  type
}) => {
  return (

    <div class="card mb-3" >
      <div class="row">
        <div class="col imageSize-activity-user ">
          <div className="d-flex ">
            {!nft_id.file_ext?.includes("video") ? <img
              src={
                nft_id.file_url
                  ? `${henceforthApi.FILES.imageMedium(nft_id.file_url)}`
                  : profile_placeholder
              }
              className="me-2 imageSize-activity"
              alt=""
            /> : <video src={henceforthApi.FILES.video(nft_id.file_url)}></video>}
            <div class="card-body">
              <h3 class="card-title mb-1 text-theme fs-14" > {nft_id ? nft_id.name : `Not Available`}</h3>

              <h3 class="card-text fw-bold mt-0 mb-0">{type && type ? type : "type not available"}</h3>
              <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
            </div>
          </div>
        </div>
        {/* <div class="col">
          <div class="card-body">
            <h3 class="card-title mb-1 text-theme fs-14" > {nft_id ? nft_id.name : `Not Available`}</h3>

            <h3 class="card-text fw-bold mt-0 mb-0">{type && type ? type : "type not available"}</h3>
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
          </div>
        </div> */}
      </div>
    </div>


  );
};

export const MarketDetailsPlayer = ({
  file_ext,
  file_url,
  setImageUrl,
}) => {
  if (String(file_ext).includes("video")) {
    return (
      <video controls autoPlay muted loop>
        <source src={henceforthApi.FILES.video(file_url)}></source>
      </video>

    );
  } else if (file_url) {
    return (
      <div data-bs-toggle="modal" data-bs-target="#openPhotosModal">
        {" "}
        <img
          src={henceforthApi.FILES.imageOriginal(file_url)}
          alt={file_url}
          onClick={() => setImageUrl(henceforthApi.FILES.imageOriginal(file_url))}
        />
      </div>
    );
  } else return <Spinner />;
};
export const NftPlayer = ({ selectedFile, setSelectedFile, loading }) => {
  return (
    <div className="upload-times">
      {String(selectedFile.type).includes("image") ? (
        <img src={URL.createObjectURL(selectedFile)} />
      ) : (
        <video controls autoPlay muted loop>
          {" "}
          <source src={URL.createObjectURL(selectedFile)}></source>
        </video>
      )}
      <i
        className="fa fa-times"
        onClick={() => (!loading ? setSelectedFile(null) : alert("Not allow"))}
      ></i>
    </div>
  );
};
export const CreatorListLayout = ({
  _id,
  profile_pic,
  first_name,
  last_name,
  user_name,
}) => {
  return (
    <div className="week">
      <Link to={`/creator/${_id}`}>
        <div className="week-img">
          <img
            src={
              profile_pic
                ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${profile_pic}`
                : profile_placeholder
            }
          />
        </div>
        <div className="week-text d-flex">
          <img src={verify} />
          <div className="img-text">
            <h3 title={`${first_name} ${last_name}`}>
              {first_name} {last_name}
            </h3>
            <p title={user_name}>@{user_name}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};
export const BidsPlacedBy = ({
  _id,
  bid_amount,
  created_at,
  bid_placed_by,
}) => {
  return (
    <>
      <div className="boxess mt-3">
        <div className="brown-img">
          <img
            src={
              bid_placed_by?.profile_pic
                ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${bid_placed_by?.profile_pic}`
                : profile_placeholder
            }
          />
        </div>
        <div className="both-added align-items-center">
          <div className="brown-text">
            <h2>
              {" "}
              <Link
                to={`/${bid_placed_by?.account_type == "USER" ? "user" : "creator"
                  }/${bid_placed_by._id}`}
              >
                <span>@{bid_placed_by.user_name}</span>
              </Link>
            </h2>
            <p>
              {moment(Number(created_at)).format("MMM DD, YYYY")} at{" "}
              {moment(Number(created_at)).format("hh:mm:a")}
            </p>
          </div>
          <div className="brown-text">
            <div className="bown-num">
              <h4>
                <strong>{bid_amount} ELEN</strong>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
