import { Link, useMatch } from "react-router-dom";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import { useState, useEffect, useContext, Fragment } from "react";
import henceforthApi from "../utils/henceforthApi";

import { GlobalContext } from "../context/Provider";
import Swal from "sweetalert2";
import moment from "moment";
import { ExploreCard, Activity } from "../components/ExploreComp";
import { WALLET_SLICE_WALLET } from "../constants/actionTypes";
import Tiktok from "../assets/images/tiktok-brands.svg";
import { toast } from "react-toastify";
import DataNotAvailable from "../components/DataNotAvailable";

export default () => {
  const match = useMatch("/user/:id");
  const { authState, currentUsd, scrollToTop } = useContext(GlobalContext);

  const [userDetails, setUserDetails] = useState({
    account_status: "",
    account_type: "",
    admin_verified: false,
    age_verified: false,
    bio: "",
    cover_img: "",
    created_at: "",
    email: "",
    email_verified: false,
    name: "",
    is_blocked: false,
    is_deleted: false,
    last_name: "",
    last_seen: null,
    profile_pic: "",
    social_token: "",
    social_type: "",
    total_coins: 0,
    updated_at: null,
    user_name: "",
    user_status: 0,
    _id: "",
  });
  henceforthApi.setToken(authState.access_token);

  const [manageUsers, setManageUsers] = useState({
    type: "",
    _id: "",
    is_blocked: false,
    is_deleted: false,
    admin_verified: false,
    language: "ENGLISH",
    account_status: "",
  });

  const [tips, setTips] = useState({
    data: [],
    total_count: 0,
  });

  const [ownNfts, setOwnNfts] = useState({
    data: [],
    total_count: 0,
  });
  const [soldNfts, setSoldNfts] = useState({
    data: [],
    total_count: 0,
  });
  const [resaleNfts, setResaleNfts] = useState({
    data: [],
    total_count: 0,
  });
  const [createdNfts, setCreatedNfts] = useState({
    data: [],
    total_count: 0,
  });
  const [bidNfts, setBidNfts] = useState({
    data: [],
    total_count: 0,
  });
  const [favNfts, setFavNfts] = useState({
    data: [],
    total_count: 0,
  });
  const [activityNfts, setActivityNfts] = useState({
    data: [],
  });

  const handleActivate = async () => {
    let data = {
      type: "ACTIVATE/DEACTIVATE",
      _id: userDetails._id,
      is_blocked: userDetails.is_blocked,
      is_deleted: userDetails.is_deleted,
      language: "ENGLISH",
      account_status:
        userDetails.account_status == "ACTIVATED" ? `DEACTIVATED` : `ACTIVATED`,
    };

    try {
      const manage_users = await henceforthApi.User.manageUsers(data);
      console.log("mmanage users", manage_users);
      setManageUsers(data);
      {
        userDetails.account_status == "DEACTIVATED"
          ? Swal.fire({
            title: "Activated",
            text: "Account Activated Successfully",
            icon: "success",
            confirmButtonText: "OK",
          })
          : Swal.fire({
            title: "Deactivated",
            text: "Account Deactivated Successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
      }
    } catch (error) {
      console.log(error, "error is ");
    }
  };

  const getUserDetails = async () => {
    const userDetail = await henceforthApi.User.getUserDetails(match.params.id);
    console.log("user details are", userDetail);
    setUserDetails(userDetail.data);
  };

  const handleBlocking = async () => {
    let data = {
      type: "BLOCK/DELETE",
      _id: userDetails._id,
      is_blocked: !Boolean(userDetails.is_blocked),
      is_deleted: userDetails.is_deleted,
      language: "ENGLISH",
      account_status: userDetails.account_status,
    };

    try {
      const manage_users = await henceforthApi.User.manageUsers(data);
      console.log("mmanage users", manage_users);
      setManageUsers(data);
      {
        !userDetails.is_blocked
          ? Swal.fire({
            title: "User Blocked",
            text: "User Blocked  successfully",
            icon: "success",
            confirmButtonText: "OK",
          })
          : Swal.fire({
            title: "User Unblocked",
            text: "User Unblocked  successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
      }
    } catch (error) {
      console.log(error, "error is ");
      Swal.fire({
        title: "Error!",
        text: error.response.body.error_description,
        icon: "error",
        confirmButtonText: "Cancel",
      });
    }
  };

  const handleDelete = async (creatorId) => {
    let data = {
      type: "BLOCK/DELETE",
      _id: userDetails._id,
      is_blocked: userDetails.is_blocked,
      is_deleted: true,
      language: "ENGLISH",
      account_status: userDetails.account_status,
    };

    try {
      const manage_users = await henceforthApi.User.manageUsers(data);
      Swal.fire({
        title: "User Deleted",
        text: "User Deleted  successfully",
        icon: "success",
        confirmButtonText: "OK",
      });
      window.history.back();
      console.log("mmanage users", manage_users);
      setManageUsers(manage_users);
    } catch (error) {
      console.log(error, "error is ");

      Swal.fire({
        title: "Error!",
        text: error.response.body.error_description,
        icon: "error",
        confirmButtonText: "Cancel",
      });
    }
  };
  const initialiseOwnNfts = async () => {
    let apiRes = await henceforthApi.User.getOwnNfts(match.params.id, 0);
    console.log("initialiseownNfts", apiRes.data);
    setOwnNfts(apiRes.data);
  };
  const initialiseSoldNfts = async () => {
    if (!soldNfts.total_count) {
      let apiRes = await henceforthApi.User.getSoldNfts(match.params.id, 0);
      console.log("initialisesoldNfts", apiRes.data);
      setSoldNfts(apiRes.data);
    }
  };
  const initialiseResaleNfts = async () => {
    if (!resaleNfts.total_count) {
      let apiRes = await henceforthApi.User.getResaleNfts(match.params.id, 0);
      console.log("initialisesaleNfts", apiRes.data);
      setResaleNfts(apiRes.data);
    }
  };

  const initialiseCreatedNfts = async () => {
    if (!createdNfts.total_count) {
      let apiRes = await henceforthApi.User.getCreatedNfts(match.params.id, 0);
      console.log("initialiseCreatedNfts", apiRes.data);
      setCreatedNfts(apiRes.data);
    }
  };
  const initialiseBidNfts = async () => {
    if (!bidNfts.total_count) {
      let apiRes = await henceforthApi.User.getBidNfts(match.params.id, 0);
      console.log("initialisebidsNfts", apiRes.data);
      setBidNfts(apiRes.data);
    }
  };
  const initialiseFavNfts = async () => {
    if (!favNfts.total_count) {
      let apiRes = await henceforthApi.User.getFavNfts(match.params.id, 0);
      console.log("initialisefavNfts", apiRes.data);
      setFavNfts(apiRes.data);
    }
  };
  const initialiseActivityNfts = async () => {
    if (!activityNfts.total_count) {
      let apiRes = await henceforthApi.User.getActivityNfts(match.params.id, 0);
      console.log("initialiseActivityNfts", apiRes.data);
      setActivityNfts(apiRes.data);
    }
  };
  const initialiseTips = async () => {
    try {
      let apiRes = await henceforthApi.User.getTips(match.params.id, 0);
      console.log("initialiseTips res", apiRes);
      setTips(apiRes.data);
      await initialiseOwnNfts();
    } catch (error) { }
  };

  useEffect(() => {
    getUserDetails();
  }, [manageUsers]);

  useEffect(() => {
    scrollToTop();
    initialiseTips();
  }, []);
  return (
    <>
      <div className="row animated fadeInRight">
        <div className="col-md-6">
          <div className="ibox">
            <div className="ibox-title">
              <h5>User Details</h5>
            </div>
            <div className="ibox-content">
              <div className="text-center">
                <img
                  src={
                    userDetails.profile_pic
                      ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${userDetails.profile_pic}`
                      : profile_placeholder
                  }
                  alt="ProfileImage"
                  class="detail-img"
                />
              </div>
              <div className="mt-4">
                <p className="text-black mb-1 user-text">
                  <b>Name :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails.name
                      ? ` ${userDetails.name} `
                      : `Not Available`}
                  </span>
                </p>
                <p className="text-black mb-1 user-text">
                  <b>User Name:</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.user_name}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text">
                  <b>Wallet address:{" "}</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails.wallet_address
                      ? `${userDetails?.wallet_address.slice(0, 4)}...${userDetails?.wallet_address.slice(-4)}`
                      : "not available"}
                  </span>
                  {userDetails.wallet_address ? <i style={{ cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(userDetails.wallet_address); toast.success("Copied") }} class="fa fa-solid fa-copy mx-2"></i> : ""}
                </p>
                <p className="text-black mb-1 user-text">
                  <b>Followers:</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.total_followers}
                  </span>
                </p>
                <p className="text-black mb-1 user-text">
                  <b>Following:</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.total_following}
                  </span>
                </p>
                <hr />
                <p>
                  {userDetails.email ? (
                    <i className="fas fa-envelope me-2"></i>
                  ) : (
                    ""
                  )}
                  <span>
                    {userDetails.email
                      ? userDetails.email
                      : "email not available"}
                  </span>
                </p>
                <hr />
                {/* <div>
                  <a>
                    {userDetails.instagram ? (
                      <i className="fas fa-instagram me-2">Instagarm :</i>
                    ) : (
                      ""
                    )}
                    <span>
                      {userDetails.instagarm
                        ? userDetails.instagarm
                        : "not available"}
                    </span>
                  </a>
                </div> */}

                <div className="mt-4">
                  <button
                    type="button"
                    onClick={handleBlocking}
                    className="btn-default btn btn-sm me-2"
                  >
                    <i
                      class={
                        userDetails.is_blocked == false
                          ? `fas fa-unlock me-2`
                          : `fas fa-lock me-2`
                      }
                    ></i>
                    {userDetails.is_blocked == false ? `Block` : `Unblock`}
                  </button>
                  {/* <a href={`https://nftyit.com/profile?id=${userDetails._id}&token=${authState.access_token}`} target="_blank" className="btn-primary-button btn btn-sm me-2">
                                        <i class="fas fa-sign-in-alt me-2"></i>Login as User
                                    </a> */}
                </div>
                <div className="mt-3">
                  <button
                    type="button"
                    onClick={handleActivate}
                    className="btn-default btn btn-sm me-2"
                  >
                    <i
                      className={
                        userDetails.account_status == "ACTIVATED"
                          ? `fas fa-unlock me-2`
                          : `fas fa-lock me-2`
                      }
                    ></i>
                    {userDetails.account_status == "ACTIVATED"
                      ? `Deactivate`
                      : `Activate`}
                  </button>
                  <button
                    onClick={handleDelete}
                    disabled={userDetails.is_deleted ? true : false}
                    className="btn-outline-primary btn btn-sm me-2"
                  >
                    <i
                      className={
                        userDetails.is_deleted
                          ? `fa fa-ban me-2`
                          : "fas fa-trash-alt me-2"
                      }
                    ></i>
                    {userDetails.is_deleted ? `Deleted` : `Delete`}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="ibox animated fadeInRight">
            <div className="ibox-title">
              <div className="title-head">
                <h3>Instagarm </h3>
              </div>
              {userDetails?.instagram?.username ?
                <Fragment>
                  <div>
                    <a>
                      {userDetails.instagram ? (
                        <><i class="fab fa-instagram me-1"></i> Instagarm:</>
                      ) : (
                        ""
                      )}
                      <a
                        className="text-primary ms-1"
                        href={`https://instagram.com/${userDetails?.instagram?.user_name
                          ? userDetails?.instagram?.user_name
                          : "Not available"
                          }/
                    `}
                        target="_blank"
                      >
                        {userDetails?.instagram?.user_name
                          ? userDetails?.instagram?.user_name
                          : "Not available"}
                      </a>
                    </a>
                  </div>
                  <div className="table-responsive my-3">
                    <table className="table table-striped table-insta-tiktok">
                      <thead>
                        <tr>
                          <th>User name</th>
                          <th>Followers</th>
                          <th>Following</th>
                        </tr>
                      </thead>
                      {userDetails?.instagram?.user_id ?
                        <tbody>
                          <tr>
                            <td>
                              {userDetails?.instagram?.user_name
                                ? userDetails?.instagram?.user_name
                                : "not available"}
                            </td>
                            <td>
                              {" "}
                              {userDetails?.instagram?.followers_count
                                ? userDetails?.instagram?.followers_count
                                : 0}
                            </td>
                            <td>
                              {" "}
                              {userDetails?.instagram?.following_count
                                ? userDetails?.instagram?.following_count
                                : 0}
                            </td>
                          </tr>
                        </tbody> : "No data found"}
                    </table>
                  </div>
                </Fragment> : <DataNotAvailable />}
            </div>
            <div className="ibox-title my-2">
              <div className="title-head ">
                <h3>Tiktok </h3>
              </div>
              {userDetails?.tiktok?.user_name ?
                <Fragment>
                  <div>
                    <a>
                      {userDetails.instagram ? (
                        <><img src={Tiktok} className="me-1" /> Tiktok:</>
                      ) : (
                        ""
                      )}
                      <a
                        className="text-primary ms-1"
                        href={`https://tiktok.com/
                    ${userDetails?.tiktok?.user_name
                            ? userDetails?.tiktok?.user_name
                            : "Not available"
                          }`}
                        target="_blank"
                      >
                        {userDetails?.tiktok?.user_name
                          ? userDetails?.tiktok?.user_name
                          : "Not available"}
                      </a>
                    </a>
                  </div>
                  <div className="table-responsive my-3">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>User name</th>
                          <th>Followers</th>
                          <th>Following</th>
                        </tr>
                      </thead>
                      {userDetails?.tiktok?.user_id ?
                        <tbody>
                          <tr>
                            <td>
                              {" "}
                              {userDetails?.tiktok?.user_name
                                ? userDetails?.tiktok?.user_name
                                : "not available"}
                            </td>
                            <td>
                              {" "}
                              {userDetails?.tiktok?.followers_count
                                ? userDetails?.tiktok?.followers_count
                                : 0}
                            </td>
                            <td>
                              {" "}
                              {userDetails?.tiktok?.following_count
                                ? userDetails?.tiktok?.following_count
                                : 0}
                            </td>
                          </tr>
                        </tbody> : "No data found"
                      }

                    </table>
                  </div>
                </Fragment> : <DataNotAvailable />}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="ibox animated fadeInRight">
            <div className="ibox-title">
              <div className="title-head d-flex justify-content-between align-items-center flex-row mb-0">
                <h5 className="mb-0">Product NFT Listing </h5>

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Owned
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => initialiseResaleNfts()}
                    >
                      Sale
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                      onClick={() => initialiseSoldNfts()}
                    >
                      Sold
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="created-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#created"
                      type="button"
                      role="tab"
                      aria-controls="created"
                      aria-selected="false"
                      onClick={() => initialiseCreatedNfts()}
                    >
                      Created
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="bid-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#bid"
                      type="button"
                      role="tab"
                      aria-controls="bid"
                      aria-selected="false"
                      onClick={() => initialiseBidNfts()}
                    >
                      Bids
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="favourite-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#fav"
                      type="button"
                      role="tab"
                      aria-controls="fav"
                      aria-selected="false"
                      onClick={() => initialiseFavNfts()}
                    >
                      Favourite
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="activity-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#activity"
                      type="button"
                      role="tab"
                      aria-controls="activity"
                      aria-selected="false"
                      onClick={() => initialiseActivityNfts()}
                    >
                      Activity
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="ibox-content">
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row">
                    {ownNfts.data.map((res) => {
                      return (
                        <ExploreCard
                          key={res._id}
                          {...res}
                          currentUsd={currentUsd}
                          currentUser={authState._id}
                        />
                      );
                    })}
                  </div>
                  {ownNfts.total_count > 10 ? (
                    <div className="view-more mb-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <Link to={`/user/${match.params.id}/nft/own`}>
                          <button className="btn btn-xs btn-white me-2">
                            View More
                          </button>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  class="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="row">
                    {resaleNfts.data.map((res) => {
                      return (
                        <ExploreCard
                          key={res._id}
                          {...res}
                          currentUsd={currentUsd}
                          currentUser={authState._id}
                        />
                      );
                    })}
                  </div>
                  {resaleNfts.total_count > 10 ? (
                    <div className="view-more mb-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <Link to={`/user/${match.params.id}/nft/resale`}>
                          <button className="btn btn-xs btn-white me-2">
                            View More
                          </button>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  class="tab-pane fade"
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <div className="row">
                    {soldNfts.data.map((res) => {
                      return (
                        <ExploreCard
                          key={res._id}
                          {...res}
                          currentUsd={currentUsd}
                          currentUser={authState._id}
                        />
                      );
                    })}
                  </div>
                  {soldNfts.total_count > 10 ? (
                    <div className="view-more mb-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <Link to={`/user/${match.params.id}/nft/sold`}>
                          <button className="btn btn-xs btn-white me-2">
                            View More
                          </button>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  class="tab-pane fade"
                  id="created"
                  role="tabpanel"
                  aria-labelledby="created-tab"
                >
                  <div className="row">
                    {createdNfts.data.map((res) => {
                      return (
                        <ExploreCard
                          key={res._id}
                          {...res}
                          currentUsd={currentUsd}
                          currentUser={authState._id}
                        />
                      );
                    })}
                  </div>
                  {createdNfts.total_count > 10 ? (
                    <div className="view-more mb-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <Link to={`/user/${match.params.id}/nft/created`}>
                          <button className="btn btn-xs btn-white me-2">
                            View More
                          </button>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  class="tab-pane fade"
                  id="bid"
                  role="tabpanel"
                  aria-labelledby="bid-tab"
                >
                  <div className="row">
                    {bidNfts.data.map((res) => {
                      return (
                        <ExploreCard
                          key={res._id}
                          {...res}
                          currentUsd={currentUsd}
                          currentUser={authState._id}
                        />
                      );
                    })}
                  </div>
                  {bidNfts.total_count > 10 ? (
                    <div className="view-more mb-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <Link to={`/user/${match.params.id}/nft/bid`}>
                          <button className="btn btn-xs btn-white me-2">
                            View More
                          </button>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  class="tab-pane fade"
                  id="fav"
                  role="tabpanel"
                  aria-labelledby="fav-tab"
                >
                  <div className="row">
                    {favNfts.data.map((res) => {
                      return (
                        <ExploreCard
                          key={res._id}
                          {...res}
                          currentUsd={currentUsd}
                          currentUser={authState._id}
                        />
                      );
                    })}
                  </div>
                  {favNfts.total_count > 10 ? (
                    <div className="view-more mb-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <Link to={`/user/${match.params.id}/nft/fav`}>
                          <button className="btn btn-xs btn-white me-2">
                            View More
                          </button>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  class="tab-pane fade"
                  id="activity"
                  role="tabpanel"
                  aria-labelledby="activity-tab"
                >
                  <div className="row">
                    <div className="col-md-12">


                      {activityNfts.data.map((res) => {
                        return (
                          <Activity
                            key={res._id}
                            {...res}
                            currentUsd={currentUsd}
                            currentUser={authState._id}
                          />
                        );
                      })}


                    </div>
                  </div>
                  {activityNfts.total_count > 10 ? (
                    <div className="view-more mb-4">
                      <div className="d-flex align-items-center justify-content-center">
                        {/* <Link to={`/user/${match.params.id}/nft/activity`}>
                          <button className="btn btn-xs btn-white me-2">
                            View More
                          </button>
                        </Link> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
