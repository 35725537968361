import { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../../context/Provider";
const Header = (props) => {

  const { authState, logOutNow } = useContext(GlobalContext);

  return (
    <div class="row border-bottom ">
      <nav
        class="navbar navbar-static-top white-bg  "
        
        role="navigation"
        style={{ marginBottom: "0" }}
      >
        <div class="navbar-header">
          <Link
            class="navbar-minimalize minimalize-styl-2 btn  btn-primary-button"
            to="#"
            onClick={props.toggle}
          >
            <i class="fa fa-bars"></i>{" "}
          </Link>
        </div>
        <ul className="nav navbar-top-links navbar-right align-items-center">

          <li>
            <a>
              <button className="btn  btn-primary-button" onClick={() => logOutNow()}>
                <i class="fa fa-sign-out"></i> Log out
              </button>
            </a>
          </li>

        </ul>
      </nav>
    </div>
  );
};

export default Header;
