const PreviewModal = () => {
  return (
    <>
      <div
        class="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title" id="exampleModalLabel">
                Preview
              </h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="row">
                {/* <div className="col-md-12">
                  <h3>Header</h3>
                  <div className="ibox-content mb-3 w-100 border-1"></div>
                </div> */}
                <div className="col-md-12">
                  <h3>Email Body</h3>
                  <div className="ibox-content mb-3 w-100 border-1"></div>
                </div>
                {/* <div className="col-md-12">
                  <h3>Footer</h3>
                  <div className="ibox-content mb-3 w-100 border-1"></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewModal;
