import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
const ReactQuill =
  typeof window === "object" ? require("react-quill") : () => false;

export default () => {
  const { authState, authDispatch } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();

  henceforthApi.setToken(authState.access_token);
  const { id } = useParams();

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  useEffect(async () => {
    const data = {
      _id: id,
      language: "ENGLISH",
      page: "",
      limit: "",
    };
    try {
      const res = await henceforthApi.Faq.getFaqById(data);
      let valueInArr = res.data.data;

      if (Array.isArray(valueInArr)) {
        setQuestion(valueInArr[0].question);
        setAnswer(valueInArr[0].answer);
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "error",
        icon: "error",
        confirmButtonText: "Cancel",
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      _id: id,
      language: "ENGLISH",
      question: question,
      answer: answer,
    };

    try {
      const res = await henceforthApi.Faq.editFaq(data);
      console.log(res, "updated");
      Swal.fire({
        title: "Faq Updated",
        text: "Faq updated successfully",
        icon: "success",
        confirmButtonText: "OK",
      }).then((isConfirm) => {
        if (isConfirm) {
          window.history.back();
        }
      });
    } catch (error) {
      console.log(error, "error");
      Swal.fire({
        title: "Error!",
        text: error.response.body.error_description,
        icon: "error",
        confirmButtonText: "Cancel",
      });
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          henceforthApi.Faq.deleteFaq(id)
            .then((res) => {
              console.log(res, "response");
              Swal.fire({
                title: "Faq Deleted",
                text: res.data.message,
                icon: "success",
                confirmButtonText: "OK",
              });
              window.history.back();
            })
            .catch((err) => {
              console.log(err, "ERROR");

              Swal.fire({
                title: "Error!",
                text: err,
                icon: "error",
                confirmButtonText: "Cancel",
              });
            });
        } catch (error) { }
      }
    });
  };

  return (
    <div class="row animated fadeInRight">
      <div class="col-md-12">
        <div class="ibox">
          <div class="ibox-title">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="my-0">Edit Faq</h4>
              </div>
              <button
                onClick={handleDelete}
                type="button"
                className="col-auto btn-primary-button btn back-btn"
              >
                <i class="fas fa-trash me-1"></i>Delete
              </button>
            </div>
          </div>
          <div class="ibox-content">
            <form onSubmit={handleSubmit}>
              <div class="form-row row">
                <div class="form-group col-md-12">
                  <label>Title</label>
                  <input
                    pattern="^[^-\s][a-zA-Z0-9_\s-]+$"
                    title="Please enter a valid title"
                    required=""
                    name="question"
                    value={question}
                    type="text"
                    className="form-control"
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                </div>
                <div class="form-group col-md-12">
                  <label>Content</label>
                  <ReactQuill
                    theme="snow"
                    onChange={(e) => setAnswer(e)}
                    placeholder={`Write your content`}
                    value={answer}
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-4 col-sm-offset-2">
                  <Link to="/faq/1" class="btn btn-white btn-sm" type="button">
                    Cancel
                  </Link>
                  <button
                    class="btn btn-primary-button btn-sm ms-2"
                    type="submit"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
