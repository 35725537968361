import { useEffect, useState, useContext } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import Swal from "sweetalert2";
import PaginationLayout from "../components/PaginationLayout";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import { useParams } from "react-router-dom";
export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch("/tiktok-approval/:page");
  const params = useParams();
  const newParam = new URLSearchParams(location.search);
  let limit = 6;
  const { authState } = useContext(GlobalContext);
  const [state, setState] = useState({
    data: [],
    total_count: null,
  });
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");
  const [readContent, setReadContent] = useState("");
  henceforthApi.setToken(authState.access_token);

  const onChangePagination = (newVal) => {
    if (newParam.has("search")) {
      navigate(`/tiktok-approval/${newVal}?search=${newParam.get("search")}`);
    } else {
      navigate(`/tiktok-approval/${newVal}`);
    }
  };

  const onClearSearch = () => {
    const newSearchparams = new URLSearchParams();
    navigate({ search: newSearchparams.toString() });
  };
  const onSearch = async (searchData) => {
    if (searchData) {
      navigate(`/tiktok-approval/1?search=${searchData}`);
    } else {
      navigate(`/tiktok-approval/1`);
    }
  };

  const initialiseData = async () => {
    try {
      let apiRes = await henceforthApi.Common.gettiktokrequest(
        Number(params.page) - 1,
        newParam.get("search")
      );
      setState({
        ...state,
        ...apiRes.data,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      let searchData = newParam.has("search") ? newParam.get("search") : "";
      setSearch(searchData);
      initialiseData();
    };
    init();
  }, [match.params.page, newParam.get("search")]);

  const handleAccept = (id) => {
    const data = {
      language: "ENGLISH",
      status: "APPROVED",
      _id: id,
    };
    console.log("APPROVED");

    henceforthApi.Common.acceptRejectRequest(data)
      .then((res) => {
        console.log(res, "Approved succesfully");
        Swal.fire({
          title: "Approved",
          text: "Approved successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        initialiseData();
      })
      .catch((err) => {
        console.log("Approved failed", err);
        Swal.fire({
          title: "Approved failed",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
  };

  const handleReject = (id) => {
    console.log("deleted");

    const data = {
      language: "ENGLISH",
      status: "REJECTED",
      _id: id,
    };
    henceforthApi.Common.acceptRejectRequest(data)
      .then((res) => {
        console.log(res, "Rejected succesfully");
        Swal.fire({
          title: "Rejected",
          text: "Rejected successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        initialiseData();
      })
      .catch((err) => {
        console.log("Rejected failed", err);
        Swal.fire({
          title: "Rejected failed",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
  };

  return (
    <div className="ibox animated fadeInRight">
      <div className="ibox-title">
        <h5>Tiktok Requests</h5>
      </div>
      <div className="ibox-content">

        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr</th>
                <th>Name</th>
                <th>User name</th>
                <th>Verification code</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {state.data.map((res, index) => {
                return (
                  <tr key={res._id}>
                    <td>
                      {params.page === "0"
                        ? index + 1
                        : (params.page - 1) * limit + (index + 1)}
                      .
                    </td>
                    <td>
                      <div className="text-nowrap">
                        <img
                          src={
                            res.user_id?.profile_pic
                              ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.user_id?.profile_pic}`
                              : profile_placeholder
                          }
                          className="me-2 imageSize"
                          alt=""
                        />

                        {res.user_id?.name
                          ? `${res.user_id?.name} `
                          : `Not Available`}
                      </div>
                    </td>
                    <td>
                      <div className="text-nowrap">


                        <img
                          src={
                            res?.document_url
                              ? `${henceforthApi.API_FILE_ROOT_SMALL}${res?.document_url}`
                              : `${res.profile_pic}`
                          }
                          className="me-2 imageSize"
                          alt=""
                        />
                        {res.user_name}
                      </div>
                    </td>

                    <td>
                      {res.random_string}
                      <a href={`${res.video_url}`} target="_blank">
                       <button
                       
                        className="btn-white btn mx-1"
                      >
                        <i class="fas fa-video mx-1"></i>

                        Open url
                      </button></a>

                    </td>
                    <td>
                      <div className="btn-group">
                        <button
                          onClick={() => handleAccept(res._id)}
                          className="btn-white btn me-2"
                        >
                          Approve
                        </button>
                      </div>
                      <div className="btn-group">
                        <button
                          onClick={() => handleReject(res._id)}
                          className="btn-white btn me-2"
                        >
                          Reject
                        </button>
                      </div>
                    </td>



                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <PaginationLayout
          count={state.total_count}
          data={state.data}
          page={Number(params.page)}
          limit={Number(limit)}
          // loading={loading}
          onPageChange={(val) => onChangePagination(val)}
        />
      </div>

    </div>
  );
};
