import { useEffect, useState, useContext } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import {
  Link,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import PaginationLayout from "../components/PaginationLayout";

export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch("/faq/:page");
  const newParam = new URLSearchParams(location.search);
  const { authState } = useContext(GlobalContext);
  const [state, setState] = useState({
    data: [],
    total_count: null,
  });
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");
  henceforthApi.setToken(authState.access_token);

  const onChangePagination = (newVal) => {
    if (newParam.has("search")) {
      navigate(`/faq/${newVal}?search=${newParam.get("search")}`);
    } else {
      navigate(`/faq/${newVal}`);
    }
  };

  const onClearSearch = () => {
    const newSearchparams = new URLSearchParams();
    navigate({ search: newSearchparams.toString() });
  };
  const onSearch = async (searchData) => {
    if (searchData) {
      navigate(`/faq/1?search=${searchData}`);
    } else {
      navigate(`/faq/1`);
    }
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      let searchData = newParam.has("search") ? newParam.get("search") : "";
      setSearch(searchData);
      try {
        let apiRes = await henceforthApi.Faq.pagination(
          match.params.page,
          newParam.get("search")
        );
        setState({
          ...state,
          ...apiRes.data,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    init();
  }, [match.params.page, newParam.get("search")]);

  return (
    <div className="ibox animated fadeInRight">
      <div className="back-btn">
        <Link to="/faq/add" className="btn btn-primary-button">
          Add FAQ
        </Link>
      </div>
      <div className="ibox-title">
        <h5>FAQ</h5>
      </div>
      <div className="ibox-content">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSearch(search);
          }}
        >
          <div class="input-group form-group">
            <input
              value={search}
              name="searchInput"
              onChange={(e) => {
                setSearch(e.target.value);
                e.target.value ? console.log(e.target.value) : onClearSearch();
              }}
              placeholder="Search by FAQ"
              type="text"
              class="form-control form-control-sm"
            />{" "}
            <span class="input-group-append">
              <button
                type="submit"
                class="btn btn-sm btn-primary-button h-100"
                onClick={() => onSearch(search)}
              >
                Go!
              </button>
            </span>
          </div>
        </form>

        <div class="accordion" id="accordionExample">
          {state.data.map((res, index) => (
            <div class="accordion-item mb-3">
              <h2 class="accordion-header mt-0" id="headingOne">
                <button
                  class="accordion-button btn-primary-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseOne${index}`}
                  aria-expanded="true"
                  aria-controls={`collapseOne${index}`}
                >
                  {res.question}
                  {/* <span className="me-1">{page === '0' ? index + 1 : ((page - 1) * limit) + (index + 1)}.{" "} </span>{res.question}{" "} */}
                  <Link to={`/faq/edit/${res._id}`} className="edit-icon">
                    <i class="fas fa-pen"></i>
                  </Link>
                </button>
              </h2>
              <div
                id={`collapseOne${index}`}
                class="accordion-collapse collapse "
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div
                  class="accordion-body word-break-faq"
                  dangerouslySetInnerHTML={{ __html: res.answer }}
                ></div>
              </div>
            </div>
          ))}

          <PaginationLayout
            count={state.total_count}
            data={state.data}
            page={Number(match.params.page)}
            limit={10}
            // loading={loading}
            onPageChange={(val) => onChangePagination(val)}
          />
        </div>
      </div>
    </div>
  );
};
