import PaginationLayout from "../components/PaginationLayout";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import { useNavigate } from "react-router-dom";
import CountCard from "../components/countCard";
import moment from "moment";
import { toast } from "react-toastify";
import henceforthIPFS from "../utils/henceforthIPFS";
import { subscribeFormatter } from "../utils/work_contant";

export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  let limit = 10;
  const { authState } = useContext(GlobalContext);
  const params = useParams();
  const newParam = new URLSearchParams(location.search);
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [totalEarning, setTotalEarning] = useState(0);
  const [totalSales, setTotalSale] = useState(0);
  // const [searchData, setSearchData] = useState("")
  const [search, setSearch] = useState("");
  const [start_date, setStartDate] = useState(0);
  const [end_date, setEndDate] = useState(0);
  henceforthApi.setToken(authState.access_token);

  const onChangePagination = (newVal) => {
    const newParam = new URLSearchParams(location.search);
    if (newParam.has("search")) {
      navigate(`/orders/${newVal}?search=${newParam.get("search")}`);
    } else {
      navigate(`/orders/${newVal}`);
    }
  };

  const onClearSearch = () => {
    const newSearchparams = new URLSearchParams();
    navigate({ search: newSearchparams.toString() });
  };

  const onSearch = async (searchData) => {
    if (searchData) {
      navigate(`/orders/1?search=${searchData}`);
    } else {
      navigate(`/orders/1`);
    }
  };

  const downloadCsv = async () => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      let apiRes = await henceforthApi.Common.export(
        moment(start_date).format("DD/MM/YYYY"),
        moment(end_date).format("DD/MM/YYYY"),
        timezone
      );
      let data = apiRes.data.data;
      console.log(data);

      const rows = [["Id", "Sold by", "Sold to", "Price", "Commission"]];
      if (Array.isArray(data)) {
        data.map((res) => {
          rows.push([
            res._id,
            `${res.sold_by?.name} `,
            `${res.sold_to?.name} `,
            res.price,
            res.commission,
          ]);
        });
      }
      console.log(rows);

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `sales_${moment().valueOf()}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
      let closeModal = document.getElementById("closeModal");
      if (closeModal) {
        closeModal.click();
      }
    } catch (error) {
      toast.error(error.response.body.error_description);
    }
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      let searchData = newParam.has("search") ? newParam.get("search") : "";
      setSearch(searchData);
      try {
        let apiRes = await henceforthApi.Common.getSales(
          Number(params.page) - 1,
          newParam.get("search")
        );
        setTotalCount(apiRes.data.total_count);
        setPageData(apiRes.data.data);
        setTotalEarning(apiRes.data.total_earnings);
        setTotalSale(apiRes.data.total_sales);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setPageData([]);
        setTotalCount(0);
      }
    };
    init();
  }, [params.page, newParam.get("search")]);



  return (
    <>
      <div className="row">
        <Link to="#scrollToHome" className="col-md-3">
          <CountCard
            title="Earning"
            count={subscribeFormatter(Number(totalEarning))}
            currency="ELEN"
            icon="fas fa-users"
          />
        </Link>
        <Link to="#scrollToHome" className="col-md-3">
          <CountCard
            title="Sales"
            count={subscribeFormatter(Number(totalSales))}
            currency="ELEN"
            icon="fas fa-folder"
          />
        </Link>
      </div>
      <div className="ibox animated fadeInRight" id="scrollToHome">
        <div className="ibox-title d-flex align-items-center justify-content-between">
          <h5>List of all Orders</h5>
          <button
            className="btn btn-primary-button mb-1"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Export
          </button>
        </div>
        <div className="ibox-content">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSearch(search);
            }}
          >
            <div class="input-group form-group">
              <input
                value={search}
                name="searchInputSales"
                onChange={(e) => {
                  setSearch(e.target.value);
                  e.target.value
                    ? console.log(e.target.value)
                    : onClearSearch();
                }}
                placeholder="Search by Name"
                type="text"
                class="form-control form-control-sm"
              />{" "}
              <span class="input-group-append">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary-button h-100"
                  onClick={() => onSearch(search)}
                >
                  Go!
                </button>
              </span>
            </div>
          </form>

          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Nft</th>
                  <th>Sold By</th>
                  <th>Sold To</th>
                  <th>Price</th>
                  <th>Commission</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {pageData.map((res, index) => {
                  return (
                    <tr key={res._id}>
                      <td>
                        {params.page === "0"
                          ? index + 1
                          : (params.page - 1) * limit + (index + 1)}
                        .
                      </td>
                      <td>
                        <div className="text-nowrap imageSize-video">
                          {!res.content_id?.file_ext?.includes("video") ? (
                            <img
                              src={henceforthApi.FILES.imageSmall(res.content_id?.file_url)}
                              className="me-2 imageSize"
                              alt=""
                            ></img>
                          ) : (
                            <video
                              src={
                                henceforthApi.FILES.video(res.content_id?.file_url)
                              }

                            ></video>
                          )}
                          {res.content_id?.name
                            ? `${res.content_id?.name} `
                            : `Not Available`}
                        </div>
                      </td>
                      <td>
                        <div className="text-nowrap">
                          <img
                            src={
                              res.sold_by?.profile_pic
                                ? `${henceforthApi.API_FILE_ROOT_SMALL}${res?.sold_by?.profile_pic}`
                                : profile_placeholder
                            }
                            className="me-2 imageSize"
                            alt=""
                          />
                          {res.sold_by?.name
                            ? `${res.sold_by?.name} `
                            : `Not Available`}
                        </div>
                      </td>
                      <td>
                        <div className="text-nowrap">
                          <img
                            src={
                              res.sold_to?.profile_pic
                                ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.sold_to?.profile_pic}`
                                : profile_placeholder
                            }
                            className="me-2 imageSize"
                            alt=""
                          />
                          {res.sold_to?.name
                            ? `${res.sold_to?.name} `
                            : `Not Available`}
                        </div>
                      </td>
                      <td>{parseFloat(Number(res.price).toFixed(6))}</td>
                      <td>{parseFloat(Number(res.commission).toFixed(6))}</td>
                      <td>
                        <div className="text-nowrap">
                          {moment(Number(res.created_at)).format("MMM DD, YYYY")}
                        </div>
                      </td>

                      <td>
                        {/* <div className="d-flex">
                          <Link to={`/user/${res._id}`} className="btn btn-xs btn-white me-2">
                            <i className="fas fa-eye me-1"></i>
                            View
                          </Link>
                        </div> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <PaginationLayout
            count={totalCount}
            data={pageData}
            page={Number(params.page)}
            limit={Number(limit)}
            // loading={loading}
            onPageChange={(val) => onChangePagination(val)}
          />
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalLabel">
                Download CSV
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModal"
              ></button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label">Date From</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setStartDate(e.target.valueAsNumber)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Date To</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setEndDate(e.target.valueAsNumber)}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer ">
              <button
                type="button"
                class="btn btn-primary-button"
                onClick={() => downloadCsv()}
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
