import ProfileImage from "../assets/images/profile_small_placeholder.png";
import { Link } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";
import { useContext, useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../context/Provider";
import PaginationLayout from "../components/PaginationLayout";
import moment from "moment";
import { WALLET_SLICE_WALLET } from "../constants/actionTypes";

export default () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [start_date, setStartDate] = useState(0);
  const [end_date, setEndDate] = useState(0);

  const [totalCount, setTotalCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const { authState } = useContext(GlobalContext);

  const newParam = new URLSearchParams(location.search);

  henceforthApi.setToken(authState.access_token);
  let limit = 10;

  const [searchData, setSearchData] = useState("");

  const onChangePagination = (newVal) => {
    const newParam = new URLSearchParams(location.search);
    if (newParam.has("search")) {
      navigate(`/creators/${newVal}?search=${newParam.get("search")}`);
    } else {
      navigate(`/creators/${newVal}?`);
    }
  };

  const onSearch = async (searchData) => {
    if (searchData) {
      navigate(`/creators/1?search=${searchData}`);
    } else {
      navigate(`/creators/1`);
    }
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      let searchData = newParam.has("search") ? newParam.get("search") : null;
      setSearchData(searchData);
      try {
        let apiRes = await henceforthApi.Creator.getListing(
          searchData,
          Number(params.page) - 1,
          limit
        );
        setTotalCount(apiRes.data.total_count);
        setData(apiRes.data.data);
        setLoading(false);
        console.log(apiRes.data);
      } catch (error) {
        setLoading(false);
        setData([]);
        setTotalCount(0);
      }
    };
    init();
  }, [params.page, newParam.get("search")]);

  const downloadCsv = async () => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      let apiRes = await henceforthApi.Creator.export(
        moment(start_date).format("DD/MM/YYYY"),
        moment(end_date).format("DD/MM/YYYY"),
        timezone
      );
      let data = apiRes.data.data;
      console.log(data);

      const rows = [
        [
          "id",
          "account_status",
          "account_type",
          "admin_verified",
          "age_verified",
          "bio",
          "cover_img",
          "created_at",
          "device_type",
          "email",
          "email_verified",
          "first_name",
          "last_name",
          "is_blocked",
          "is_deleted",
          "last_seen",
          "profile_pic",
          "social_token",
          "social_type",
          "total_coins",
          "updated_at",
          "user_name",
          "user_status",
        ],
      ];

      if (Array.isArray(data)) {
        data.map((res) => {
          rows.push([
            res._id,
            res.account_status,
            res.account_type,
            res.admin_verified,
            res.age_verified,
            res.bio,
            res.cover_img,
            res.created_at,
            res.device_type,
            res.email,
            res.email_verified,
            res.first_name,
            res.last_name,
            res.is_blocked,
            res.is_deleted,
            res.last_seen,
            res.profile_pic,
            res.social_token,
            res.social_type,
            res.total_coins,
            res.updated_at,
            res.user_name,
            res.user_status,
          ]);
        });
      }

      console.log(rows);

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `creator_${moment().valueOf()}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
      let closeModal = document.getElementById("closeModal");
      if (closeModal) {
        closeModal.click();
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="ibox animated fadeInRight">
        <div className="ibox-title d-flex align-items-center justify-content-between">
          <h5>List of all Creators</h5>
          <button
            className="btn btn-primary-button mb-1"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Export
          </button>
        </div>

        <div className="ibox-content">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div class="input-group form-group">
              <input
                value={searchData}
                name="searchInput"
                onChange={(e) => {
                  setSearchData(e.target.value);
                  onSearch(e.target.value);
                }}
                placeholder="Search by Name, Username or Email"
                type="text"
                class="form-control form-control-sm"
              />{" "}
              <span class="input-group-append">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary-button h-100"
                >
                  Go!
                </button>
              </span>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>
                  <th>Creator</th>
                  <th>Owner</th>
                  <th>Price</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((res, index) => {
                  return (
                    <tr key={res._id}>
                      <td>
                        {params.page === "0"
                          ? index + 1
                          : (params.page - 1) * limit + (index + 1)}
                        .
                      </td>
                      <td>
                        <img
                          src={res.file_url ? `${res.file_url}` : ProfileImage}
                          className="me-2 imageSize"
                          alt=""
                        />
                        {res.name ? `${res.name}` : `Not Available`}
                      </td>
                      <td>
                        <img
                          src={
                            res.created_by?.profile_pic
                              ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.created_by.profile_pic}`
                              : ProfileImage
                          }
                          className="me-2 imageSize"
                          alt=""
                        />
                        {res.created_by.name
                          ? `${res.created_by.name}`
                          : `Not Available`}
                      </td>
                      <td>
                        <img
                          src={
                            res.current_owner.profile_pic
                              ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.current_owner.profile_pic}`
                              : ProfileImage
                          }
                          className="me-2 imageSize"
                          alt=""
                        />
                        {res.current_owner.name
                          ? `${res.current_owner.name}`
                          : `Not Available`}
                      </td>

                      <td>{res.price ? res.price : "Not available"}</td>

                      <td>{res.type ? res.type : "type not available"}</td>
                      <td>
                        <div className="d-flex">
                          <Link
                            to={`/nft/${res._id}?type=WEEKLY`}
                            className="btn btn-xs btn-white me-2"
                          >
                            <i className="fas fa-eye me-1"></i>
                            View
                          </Link>
                          {/* <a href={`https://nftyit.com/profile?id=${res._id}&token=${authState.access_token}`} target="_blank" className="btn btn-xs btn-primary-button">
                            <i className="fas fa-sign-in-alt me-1"></i>
                            Login as Creator
                          </a> */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <PaginationLayout
            count={totalCount}
            data={data}
            page={Number(params.page)}
            limit={Number(limit)}
            loading={loading}
            onPageChange={(val) => onChangePagination(val)}
          />
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalLabel">
                Download CSV
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModal"
              ></button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label">Date From</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setStartDate(e.target.valueAsNumber)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Date To</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setEndDate(e.target.valueAsNumber)}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer ">
              <button
                type="button"
                class="btn btn-primary-button"
                onClick={() => downloadCsv()}
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
