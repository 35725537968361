import profile_placeholder from "../assets/images/profile_placeholder.png";
import {
  Link,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import PaginationLayout from "../components/PaginationLayout";

export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch("/staffs/:page/:role");
  const newParam = new URLSearchParams(location.search);
  let limit = 10;
  const { authState, staffMembers } = useContext(GlobalContext);
  const [state, setState] = useState({
    data: [],
    total_count: null,
  });
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");
  henceforthApi.setToken(authState.access_token);

  const onChangePagination = (newVal) => {
    if (newParam.has("search")) {
      navigate(
        `/staffs/${newVal}/${match.params.role}?search=${newParam.get(
          "search"
        )}`
      );
    } else {
      navigate(`/staffs/${newVal}/${match.params.role}`);
    }
  };
  const onChangeRole = (role) => {
    if (newParam.has("search")) {
      navigate(`/staffs/1/${role}?search=${newParam.get("search")}`);
    } else {
      navigate(`/staffs/1/${role}`);
    }
  };

  const onClearSearch = () => {
    const newSearchparams = new URLSearchParams();
    navigate({ search: newSearchparams.toString() });
  };
  const onSearch = async (searchData) => {
    if (searchData) {
      navigate(`/staffs/1/${match.params.role}?search=${searchData}`);
    } else {
      navigate(`/staffs/1/${match.params.role}`);
    }
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      let searchData = newParam.has("search") ? newParam.get("search") : "";
      setSearch(searchData);
      try {
        let apiRes = await henceforthApi.Staff.pagination(
          match.params.page,
          newParam.get("search"),
          match.params.role
        );
        setState({
          ...state,
          ...apiRes.data,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    init();
  }, [match.params.page, match.params.role, newParam.get("search")]);

  const searchRegex = new RegExp(/^[A-Za-z]+$/);

  // const refreshPage = () => {
  //   // window.location.refreshPage(false)
  // }

  return (
    <div className="ibox animated fadeInRight">
      <div className="ibox-title d-flex align-items-center justify-content-between">
        <h5>Staff Management</h5>
        <Link to="/staff/add" className="btn btn-primary-button mb-1 back-btn">
          Add Staff
        </Link>
        <div class="dropdown">
          <button
            class="filter-by-role-btn btn btn-secondary dropdown-toggle"
            type="button"
            id="staffDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {match.params.role == "all"
              ? "Fitler by role"
              : String(match.params.role).replace("_", " ")}
          </button>
          <ul class="dropdown-menu" aria-labelledby="staffDropdown">
            {staffMembers.map((res) => {
              return (
                <li onClick={() => onChangeRole(res)}>
                  <a class="dropdown-item" href="#">
                    {String(res).replace("_", " ")}
                  </a>
                </li>
              );
            })}
            {match.params.role !== "all" ? (
              <li onClick={() => onChangeRole("all")}>
                <a class="dropdown-item" href="#">
                  Reset
                </a>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>

      <div className="ibox-content">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSearch(search);
          }}
        >
          <div class="input-group form-group">
            <input
              value={search}
              name="searchInput"
              onKeyPress="searchRegex"
              onChange={(e) => {
                setSearch(e.target.value);
                e.target.value ? console.log(e.target.value) : onClearSearch();
              }}
              placeholder="Search by Name, Email"
              type="text"
              class="form-control form-control-sm"
            />{" "}
            <span class="input-group-append">
              <button
                type="submit"
                class="btn btn-sm btn-primary-button h-100"
                onClick={() => onSearch(search)}
              >
                Go!
              </button>
            </span>
          </div>
        </form>

        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr no</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {state.data.map((res, index) => {
                return (
                  <tr>
                    <td>
                      {match.params.page === "0"
                        ? index + 1
                        : (match.params.page - 1) * limit + (index + 1)}
                      .
                    </td>
                    <td>
                      <img
                        src={
                          res.image
                            ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.image}`
                            : profile_placeholder
                        }
                        className="me-2 imageSize"
                        alt=""
                      />
                      {res.name}
                    </td>
                    <td>{res.email}</td>

                    <td>
                      {res.roles.length > 3
                        ? res.roles.slice(0, 3) + "..."
                        : res.roles + ""}
                      {/* {(Array.isArray(res.roles) && res.roles.map(res1 => { return <span className="label ">{res1+","}</span> }))} */}
                    </td>
                    <td>
                      <Link
                        to={`/staff/${res._id}`}
                        className="btn btn-xs btn-white"
                      >
                        <i className="fas fa-eye me-1"></i>
                        View
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <PaginationLayout
          count={state.total_count}
          data={state.data}
          page={Number(match.params.page)}
          limit={10}
          // loading={loading}
          onPageChange={(val) => onChangePagination(val)}
        />
      </div>
    </div>
  );
};
