import { Link } from "react-router-dom";

const Data = [
  {
    id: 1,
    key: "user_name",
    english: "Username",
    arabic: "asm aalmustakhdam",
  },
  {
    id: 2,
    key: "user_name",
    english: "Username",
    arabic: "asm aalmustakhdam",
  },
  {
    id: 3,
    key: "user_name",
    english: "Username",
    arabic: "asm aalmustakhdam",
  },
  {
    id: 4,
    key: "user_name",
    english: "Username",
    arabic: "asm aalmustakhdam",
  },
];

const Users = () => {
  return (
    <div className="ibox animated fadeInRight">
      <div className="ibox-title">
        <h5>Language String Listing</h5>
      </div>
      <div className="ibox-content">
        <div class="input-group form-group">
          <input
            placeholder="Search by Name"
            type="text"
            class="form-control form-control-sm"
          />{" "}
          <span class="input-group-append">
            <button type="button" class="btn btn-sm btn-primary-button h-100">
              Go!
            </button>
          </span>
        </div>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr no</th>
                <th>Key</th>
                <th>English string</th>
                <th>Arabic string</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Data.map((res) => {
                return (
                  <tr>
                    <td>{res.id}</td>

                    <td>{res.key}</td>
                    <td>{res.english}</td>
                    <td>{res.arabic}</td>
                    <td>
                      <Link
                        to={`/language/edit/${res.id}`}
                        className="btn btn-xs btn-white"
                      >
                        <i className="fas fa-edit me-2"></i>
                        Edit
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* <Pagination /> */}
      </div>
    </div>
  );
};

export default Users;
