import CountCard from "../components/countCard";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import { Link, useLocation } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import { useEffect, useContext } from "react";
import staticDataDashboardSuccess from "../context/actions/auth/staticDataDashboardSuccess";
import NFTPriceBarChar from "../components/timeOrderGraph/NFTPriceBarChar";
import moment from "moment";
import { ERROR_UNAUTHORIZED } from "../constants/actionTypes";
import { toast } from "react-toastify";
import { subscribeFormatter } from "../utils/work_contant";
export default () => {
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const { authState, staticDataState, staticDataDispatch, scrollToTop, logOutNow } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);
  const dashboard = staticDataState.dashboard;

  const initialiseProfile = async () => {
    try {
      const dbData = await henceforthApi.Dashboard.getPagnation();
      staticDataDashboardSuccess(dbData.data)(staticDataDispatch);

    } catch (error) {
      if (error?.response?.body?.error == ERROR_UNAUTHORIZED) {
        logOutNow()
      } else {
        toast.error(error.response.body.error_description)
      }
    }
  }
  useEffect(initialiseProfile, [searchParam.get("type")])

  useEffect(() => {
    scrollToTop();
  }, []);


  return (
    <div className="animated fadeInRight">
      <div className="row">
        <Link to="/users/1" className="col-md-3">
          <CountCard
            title="Users"
            count={subscribeFormatter(dashboard.total_users)}
            currency=""
            icon="fas fa-users"
          />
        </Link>
        <Link to="/orders/1" className="col-md-3">
          <CountCard
            title="Orders"
            count={subscribeFormatter(dashboard.total_orders)}
            currency=""
            icon="fas fa-folder"
          />
        </Link>
        <Link to="/products/1" className="col-md-3">
          <CountCard
            title="Products"
            count={subscribeFormatter(dashboard.total_nfts)}
            currency=""
            icon="fas fa-users"
          />
        </Link>
        <Link to="/orders/1" className="col-md-3">
          <CountCard
            title="Total Sale"
            count={subscribeFormatter(Number(dashboard.total_sales))}
            currency="ELEN"
            icon="fas fa-folder"
          />
        </Link>
        <Link to="/orders/1" className="col-md-3">
          <CountCard
            title="Total Earning"
            count={subscribeFormatter(Number(dashboard.total_earnings))}
            currency="ELEN"
            icon="fas fa-folder"
          />
        </Link>
      </div>

      <NFTPriceBarChar />


      <div className="row">
        <div className="col-md-6">
          <div class="ibox">
            <div class="ibox-title">
              <h5>Recent Products</h5>
            </div>
            <div class="ibox-content">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboard?.recent_nfts?.map((res, index) => {
                      return (
                        <tr>
                          <td>{index + 1}.</td>
                          <td>
                            <div className="text-nowrap imageSize-video d-flex">
                              {!res.file_ext?.includes("video") ? <img
                                src={henceforthApi.FILES.imageSmall(res.file_url)}
                                className="me-2 imageSize"
                                alt=""
                              ></img> :
                                <video>
                                  <source className="video-resize" src={henceforthApi.FILES.video(res.file_url)}></source>
                                </video>
                              }
                              <span className="text-truncate mx-1 my-2">{res.name ? res.name : "Not available"}</span>
                            </div>
                          </td>
                          <td>
                            <Link
                              to={`/product/${res._id}?type=weekly`}
                              className="btn btn-xs btn-white"
                            >
                              <i className="fas fa-eye me-1"></i>View
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className="view-more mb-2">
                  <div className="d-flex align-items-center justify-content-center">
                    <Link
                      to="/products/1"
                      className="btn btn-xs btn-white me-2"
                    >
                      View More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="col-md-6">
          <div class="ibox">
            <div class="ibox-title">
              <h5>Recent Users</h5>
            </div>
            <div class="ibox-content">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboard?.recent_users?.map((res, index) => {
                      return (
                        <tr>
                          <td>{index + 1}.</td>
                          <td>
                            <div className="text-nowrap">

                              <img
                                src={
                                  res.profile_pic
                                    ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.profile_pic}`
                                    : profile_placeholder
                                }
                                className="me-2 imageSize"
                                alt=""
                              />
                              {res.user_name ? res.user_name : "Not available"}

                            </div>
                          </td>
                          <td> {res?.email?.length > 20 ? `${res?.email?.slice(0, 19)}...` : res?.email}</td>
                          <td>
                            <Link
                              to={`/user/${res._id}`}
                              className="btn btn-xs btn-white"
                            >
                              <i className="fas fa-eye me-1"></i>
                              View
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className="view-more mb-2">
                  <div className="d-flex align-items-center justify-content-center">
                    <Link to="/users/1" className="btn btn-xs btn-white me-2">
                      View More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div className="col-md-12">
          <div class="ibox">
            <div class="ibox-title">
              <h5>Recent Sales</h5>
            </div>
            <div class="ibox-content">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Nft</th>
                      <th>Sold By</th>
                      <th>Sold To</th>
                      <th>Price</th>
                      <th>Commission</th>
                      <th>Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboard?.reecent_sales?.map((res, index) => {
                      return (
                        <tr key={res._id}>
                          <td>{index + 1}.</td>
                          <td>
                            <div className="text-nowrap imageSize-video d-flex">
                              {!res.nft_id?.file_ext?.includes("video") ? <img
                                src={henceforthApi.FILES.imageSmall(res.nft_id?.file_url)}
                                className="me-2 imageSize"
                                alt=""
                              ></img> :

                                <video>
                                  <source className="video-resize" src={henceforthApi.FILES.video(res.nft_id?.file_url)}></source>
                                </video>
                              }
                              <span className="text-truncate">{res?.nft_id?.name ? res?.nft_id?.name : "Not available"}</span>
                            </div>
                          </td>
                          <td>
                            <div className="text-nowrap">
                              <img
                                src={
                                  res?.sold_by?.profile_pic
                                    ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.sold_by?.profile_pic}`
                                    : profile_placeholder
                                }
                                className="me-2 imageSize"
                                alt=""
                              />
                              {res.sold_by?.name
                                ? `${res.sold_by?.name} `
                                : `Not Available`}
                            </div>
                          </td>
                          <td>
                            <div className="text-nowrap">
                              <img
                                src={
                                  res.sold_to?.profile_pic
                                    ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.sold_to?.profile_pic}`
                                    : profile_placeholder
                                }
                                className="me-2 imageSize"
                                alt=""
                              />
                              {res.sold_to?.name
                                ? `${res.sold_to?.name} `
                                : `Not Available`}
                            </div>
                          </td>
                          <td>{res.price}</td>
                          <td>
                            {parseFloat(Number(res.commission).toFixed(10))}
                          </td>
                          <td>
                            <div className="text-nowrap">
                              {moment(Number(res.created_at)).format(
                                "MMM DD, YYYY"
                              )}
                            </div>
                          </td>
                          <td>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="view-more mb-2">
                  <div className="d-flex align-items-center justify-content-center">
                    <Link to="/orders/1" className="btn btn-xs btn-white me-2">
                      View More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



















