export default {
    dashboard: {
        order_count: 0,
        total_nfts: 0,
        total_creators: 0,
        total_users: 0,
        total_sales: 0,
        total_earnings: 0,
        graph_data: [],
        recent_creators: [],
        recent_users: [],
        recent_nfts: [],
        loaded: false
    },
    nfts: {
        count_data: 0,
        data: [],
        loaded: false
    }
}