export const Spinner = (props) => {

    return <div class={`spinner-border spinner-grow-sm`}  role="status">
        <span class="visually-hidden"></span>
    </div>
}

export const GlobalLoading = () => {
    return <div class="modal fade steps show" id="exampleModalLive" tabindex="-1" aria-labelledby="exampleModalLiveLabel" aria-modal="true" role="dialog" style={{ display: "block" }}>
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div className="nft-body-modal">
                        <div className="upload-stepss">
                            <div className="step-img">
                                <Spinner />
                            </div>
                            <div className="step-text">
                                <h2>Loading...</h2>
                                <p>Please Wait!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export const ChipButton = ({ _id, name, onClick }) => {
    return <button type="button" key={_id} className="btn btn-aus bg-light mx-1" onClick={() => onClick(_id, name)}>{name} <i class="fal fa-times-circle ml-1"></i></button>
}