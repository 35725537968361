import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { Fragment, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/Provider";
import henceforthApi from "../../utils/henceforthApi";
import PaginationLayout from "../../components/PaginationLayout";
import moment from "moment";
import { toast } from "react-toastify";
import { Spinner } from "../../components/BootstrapCompo";

const WeeklySectionEdit = () => {

    const location = useLocation();
    const match = useMatch("/weekly-section/:type/edit/:page");
    const navigate = useNavigate();
    const newParam = new URLSearchParams(location.search);
    const [state, setState] = useState({
        data: [],
        total_count: null
    })

    const {
        authState,
        currentUsd,
        scrollToTop,
    } = useContext(GlobalContext);

    const limit = 9;

    henceforthApi.setToken(authState.access_token);

    const onSearchNft = (nftSearch) => {
        const newSearchparams = new URLSearchParams();
        if (nftSearch) {
            newSearchparams.set("search", nftSearch);
        }
        navigate(`/weekly-section/${match.params.type}/edit/1?${newSearchparams.toString()}`);
    };
    const onChangePagination = (newVal) => {
        const newParam = new URLSearchParams(location.search);
        if (newParam.has("search")) {
            navigate(`/weekly-section/${match.params.type}/edit/${newVal}?search=${newParam.get("search")}`);
        } else {
            navigate(`/weekly-section/${match.params.type}/edit/${newVal}`);
        }
    };
    const initialiseNfts = async (page) => {
        let searchData = newParam.has("search") ? newParam.get("search") : "";
        try {
            let apiRes = await henceforthApi.Nfts.pagination(
                page - 1,
                newParam.get("search")
            );
            setState({
                ...state,
                ...apiRes.data
            })
        } catch (error) {
            toast.error(error?.response?.body?.error_desctiption)
        };
    }
    useEffect(() => {
        scrollToTop();
        initialiseNfts(Number(match.params.page));
    }, [match.params.page, newParam.get("search")]);


    const updateWeekly = async (id, weekly, index) => {
        let data = state.data
        data[index].loading = true
        setState({
            ...state,
            data
        })
        const items = {
            _id: id,
            language: "ENGLISH"
        }
        let apiRes;
        try {

            if (weekly) {
                apiRes = await henceforthApi.Weekly.remove(items)
            } else {
                apiRes = await henceforthApi.Weekly.add(items)
            }
            if (apiRes) {
                let newData = apiRes.data
                data[index].weekly = newData.weekly
            }
            data[index].loading = false
            setState({
                ...state,
                data
            })
        } catch (error) {
            data[index].loading = false
            setState({
                ...state,
                data
            })
        }
    }

    return (
        <div class="row animated fadeInRight">
            <div class="col-md-12">
                <div className="ibox">
                    <div className="ibox-content">
                        <form
                            className="row"
                            onSubmit={(e) => {
                                e.preventDefault();
                                onSearchNft();
                            }}>
                            <div className="col-md-12">
                                <div class="input-group form-group">
                                    <input
                                        placeholder="Search by Name"
                                        type="text"
                                        class="form-control form-control-sm"
                                        value={
                                            newParam.has("search") ? newParam.get("search") : ""
                                        }
                                        onChange={(e) => {
                                            onSearchNft(e.target.value);
                                        }}
                                    />
                                    <span class="input-group-append">
                                        <button
                                            type="submit"
                                            class="btn btn-sm btn-primary-button h-100"
                                        >
                                            Go!
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </form>

                        <div className="row">
                            {state?.data?.map((res, index) => {
                                return (
                                    <div class="col-lg-4 col-md-6">
                                        <div class="row">
                                            <div class="col">
                                                <div class=" mb-4 product-box">
                                                    <Link to={`/product/${res._id}?type=weekly`}>
                                                        <div className="explore-img">
                                                            {String(res.file_ext).includes("video") ? (
                                                                <video autoPlay muted loop>

                                                                    <source src={henceforthApi.FILES.video(res.file_url)}></source>
                                                                </video>
                                                            ) : (
                                                                <img src={henceforthApi.FILES.imageOriginal(res.file_url)} />
                                                            )}
                                                        </div>
                                                    </Link>
                                                    <div class="card-body product-content">
                                                        <h2 className="fw-bold mt-0 mb-0 text-truncate">{res.name}</h2>

                                                        <div className="both-added my-2">
                                                            <div className="d-flex align-items-center">
                                                                <div className="brown-img me-2">
                                                                    {res?.current_owner ? (
                                                                        <img
                                                                            src={
                                                                                res?.current_owner?.profile_pic
                                                                                    ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${res?.current_owner.profile_pic}`
                                                                                    : res?.profile_placeholder
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={
                                                                                res?.created_by?.profile_pic
                                                                                    ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${res?.created_by?.profile_pic}`
                                                                                    : res?.profile_placeholder
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="">
                                                                    <h3 className="fw-bold mb-0">
                                                                        {res?.current_owner ? res?.current_owner?.name : res?.created_by?.name}{" "}
                                                                        {res?.current_owner
                                                                            ? res?.current_owner?.last_name
                                                                            : res.created_by?.last_name}
                                                                    </h3>
                                                                    <p className="mb-0 text-gray">
                                                                        @
                                                                        {res.current_owner
                                                                            ? res?.current_owner?.user_name
                                                                                ? res?.current_owner?.user_name
                                                                                : "Not available"
                                                                            : res?.created_by?.user_name
                                                                                ? res?.created_by?.user_name
                                                                                : "Not available"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="brown-text text-wrap">
                                                                <h2>{moment(Number(res?.created_at)).format("MMM DD, YYYY")}</h2>
                                                                <p>Minted</p>
                                                            </div>
                                                        </div>

                                                        <hr className="my-2 mb-1" />
                                                        <div class="d-flex justify-content-between align-items-center">
                                                            <div class="btn-group">
                                                                <div className="bown-num ">
                                                                    <h4 className="mb-0">{res.price} ELEN</h4>
                                                                    <h5 className="mb-0">
                                                                        ${Number(currentUsd * Number(res.price)).toFixed(3)} USD
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <button
                                                                className="btn btn-primary-button" onClick={() => updateWeekly(res._id, res.weekly, index)} disabled={res.loading}>
                                                                {res.loading ? <Spinner /> : res.weekly ? 'Remove' : <Fragment>Add <i class="fa fa-long-arrow-right ms-1"></i></Fragment>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <PaginationLayout
                            count={state.total_count}
                            data={state.data}
                            page={Number(match.params.page)}
                            limit={Number(limit)}
                            // loading={loading}
                            onPageChange={(val) => onChangePagination(val)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WeeklySectionEdit