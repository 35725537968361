import { useEffect, useState, useContext } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import Swal from "sweetalert2";
import PaginationLayout from "../components/PaginationLayout";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch("/contact-us/:page");
  const params = useParams();
  const newParam = new URLSearchParams(location.search);
  let limit = 6;
  const { authState } = useContext(GlobalContext);
  const [state, setState] = useState({
    data: [],
    total_count: null,
  });
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");
  const [readContent, setReadContent] = useState("");
  henceforthApi.setToken(authState.access_token);

  const onChangePagination = (newVal) => {
    if (newParam.has("search")) {
      navigate(`/contact-us/${newVal}?search=${newParam.get("search")}`);
    } else {
      navigate(`/contact-us/${newVal}`);
    }
  };

  const onClearSearch = () => {
    const newSearchparams = new URLSearchParams();
    navigate({ search: newSearchparams.toString() });
  };
  const onSearch = async (searchData) => {
    if (searchData) {
      navigate(`/contact-us/1?search=${searchData}`);
    } else {
      navigate(`/contact-us/1`);
    }
  };

  const initialiseData = async () => {
    try {
      let apiRes = await henceforthApi.ContactUs.pagination(
        Number(params.page) - 1,
        newParam.get("search")
      );
      setState({
        ...state,
        ...apiRes.data,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      let searchData = newParam.has("search") ? newParam.get("search") : "";
      setSearch(searchData);
      initialiseData();
    };
    init();
  }, [match.params.page, newParam.get("search")]);

  const handleResolve = (id) => {
    const data = {
      language: "ENGLISH",
      type: "RESOLVE",
      _id: id,
    };
    console.log("resolved");

    henceforthApi.ContactUs.resolveContact(data)
      .then((res) => {
        console.log(res, "Resolved succesfully");
        Swal.fire({
          title: "Contact Resolved",
          text: "Contact Resolved successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        initialiseData();
      })
      .catch((err) => {
        console.log("delte failed", err);
        Swal.fire({
          title: "Contact Resolved",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
  };

  const handleDelete = (id) => {
    console.log("deleted");

    const data = {
      language: "ENGLISH",
      type: "DELETE",
      _id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          henceforthApi.ContactUs.deleteContact(data)
            .then((res) => {
              console.log(res, "Deleted succesfully");

              Swal.fire({
                title: "Contact Deleted",
                text: "Contact deleted successfully",
                icon: "success",
                confirmButtonText: "OK",
              });
              initialiseData();
            })
            .catch((err) => {
              console.log("delte failed", err);
              Swal.fire({
                title: "Contact Delete Failed",
                text: err.response.body.error_description,
                icon: "error",
                confirmButtonText: "Cancel",
              });
            });
        } catch (error) { }
      }
    });
  };

  return (
    <div className="ibox animated fadeInRight">
      <div className="ibox-title">
        <h5>Contact</h5>
      </div>
      <div className="ibox-content">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSearch(search);
          }}
        >
          <div class="input-group form-group">
            <input
              value={search}
              name="searchInput"
              onChange={(e) => {
                setSearch(e.target.value);
                e.target.value ? console.log(e.target.value) : onClearSearch();
              }}
              placeholder="Search by Email"
              type="text"
              class="form-control form-control-sm"
            />{" "}
            <span class="input-group-append">
              <button
                type="submit"
                class="btn btn-sm btn-primary-button h-100"
                onClick={() => onSearch(search)}
              >
                Go!
              </button>
            </span>
          </div>
        </form>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr</th>
                <th>Email</th>
                <th>Message</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {state.data.map((res, index) => {
                return (
                  <tr key={res._id}>
                    <td>
                      {params.page === "0"
                        ? index + 1
                        : (params.page - 1) * limit + (index + 1)}
                      .
                    </td>
                    <td>{res.email}</td>
                    <td>
                      {res.message.length < 40
                        ? res.message.slice(0, 40)
                        : res.message.slice(0, 40) + "..."}
                      {res.message.length < 40 ? (
                        ""
                      ) : (
                        <button
                          className="readmore btn btn-xs "
                          data-bs-toggle="modal"
                          data-bs-target="#readContentModal"
                          onMouseOver={() => setReadContent(res.message)}
                        >
                          Read more
                        </button>
                      )}{" "}
                    </td>
                    <td>
                      <div className="btn-group">
                        <button
                          disabled={res.resolved ? true : false}
                          onClick={() => handleResolve(res._id)}
                          className="btn btn-default btn-xs"
                        >
                          <i
                            className={
                              !res.resolved
                                ? `fas fa-info-circle me-1`
                                : `fas fa-check-circle me-1`
                            }
                          ></i>
                          {!res.resolved ? "Pending" : "Resolved"}
                        </button>
                      </div>
                    </td>

                    <td>
                      <div className="btn-group">
                        <button
                          onClick={() => handleDelete(res._id)}
                          className="btn-white btn btn-xs me-2"
                        >
                          <i class="fas fa-trash-alt me-1"></i>Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <PaginationLayout
          count={state.total_count}
          data={state.data}
          page={Number(params.page)}
          limit={Number(limit)}
          // loading={loading}
          onPageChange={(val) => onChangePagination(val)}
        />
      </div>
      <div
        class="modal fade"
        id="readContentModal"
        tabindex="-1"
        aria-labelledby="readContentModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <p className="text-break">{readContent}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
