import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Profile from "../assets/images/profile_placeholder.png";
import { GlobalContext } from "../context/Provider";
import axios from "axios";
import henceforthApi from "../utils/henceforthApi";
import loginSuccess from "../context/actions/auth/loginSuccess";
import Swal from "sweetalert2";
import { Spinner } from "../components/BootstrapCompo";

const EditProfile = () => {
  const navigate = useNavigate();
  const { authState, authDispatch } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    name: authState.name,
    email: authState.email,
  });

  const fileUpload = async (file) => {
    setLoading(true);

    const url = `${henceforthApi.API_ROOT}Upload`;
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        token: authState.access_token,
      },
    };

    const apiRes = await (await axios.post(url, formData, config)).data;

    console.log(apiRes, "upload image");
    console.log("state", state);

    const data1 = {
      language: "ENGLISH",
      name: authState.name,
    };

    henceforthApi.Administrator.editImgProfile(data1, apiRes.data.file_name)
      .then((res) => {
        setLoading(false);
        loginSuccess(res.data)(authDispatch);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: state.name,
      language: "ENGLISH",
    };

    henceforthApi.Administrator.editProfile(data)
      .then((res) => {
        loginSuccess(res.data)(authDispatch);
        Swal.fire({
          title: "Profile updated",
          text: "Admin profile updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then((isConfirm) => {
          if (isConfirm) {
            navigate(`/profile`, { replace: true });
          }
        });
      })
      .catch((err) => {
        console.log(err.response.body);
        Swal.fire({
          title: "Error!",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
    console.log(state);
  };

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <div className="row animated fadeInRight">
      <div className="col-md-12">
        <div className="ibox">
          <div className="ibox-title p-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4>Edit {authState.super_admin ? 'Admin' : 'Staff'}</h4>
              </div>
            </div>
          </div>
          <div class="ibox-content spinerr-wrapper">
            <div class="text-center my-4 spinner-inner">
              {/* {loading ?  <Spinner /> : <div className="position-relative d-inline-block"> <img className="profile-user" alt="" src={authState.image ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${authState.image}` : Profile} />
                <label className="label edit-label" htmlFor="upload-profile">
                  <i className="fa fa-camera"></i>
                </label>

                <input accept="image/*" onChange={(e) => fileUpload(e.target.files[0])} type="file" multiple id="upload-profile" className="form-control d-none" />
              </div>
              } */}

              {loading ? (
                <div class="position-relative d-inline-block">
                  <img
                    class="profile-user position-relative"
                    alt=""
                    src={
                      authState.image
                        ? `${henceforthApi.API_FILE_ROOT_SMALL}${authState.image}`
                        : Profile
                    }
                  />
                  <div
                    class={`spinner-border spinner-grow-md spinerrrrr   `}
                    role="status"
                  >
                    <span class="visually-hidden"></span>
                  </div>
                  <label class="label edit-label" for="upload-profile">
                    <i class="fa fa-camera"></i>
                  </label>
                  <input
                    onChange={(e) => fileUpload(e.target.files[0])}
                    type="file"
                    id="upload-profile"
                    class="form-control d-none"
                  />
                </div>
              ) : (
                <div class="position-relative d-inline-block">
                  <img
                    class="profile-user"
                    alt=""
                    src={
                      authState.image
                        ? `${henceforthApi.API_FILE_ROOT_SMALL}${authState.image}`
                        : Profile
                    }
                  />
                  <label class="label edit-label" for="upload-profile">
                    <i class="fa fa-camera"></i>
                  </label>
                  <input
                    onChange={(e) => fileUpload(e.target.files[0])}
                    type="file"
                    id="upload-profile"
                    class="form-control d-none"
                  />
                </div>
              )}
            </div>

            <form onSubmit={handleSubmit}>
              <div className="form-row row">
                <div className="form-group col-md-6">
                  <label> Name</label>
                  <input
                    type="text"
                    pattern="^[^-\s][a-zA-Z0-9_\s-]+$"
                    title="Please enter a valid name"
                    required=""
                    className="form-control"
                    placeholder="Name"
                    maxLength="30"
                    name="name"
                    defaultValue={authState.name}
                    onChange={(e) => handleInput(e)}
                  />
                </div>

                {/* <div className="form-group col-md-6">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last name"
                    maxLength="30"
                    name="last_name"
                    defaultValue={authState.last_name}
                    onChange={(e) => handleInput(e)}
                  />
                </div> */}
                <div className="form-group col-md-6">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    defaultValue={authState.email}
                    readOnly={true}
                  />
                </div>

                {/* <div className="form-group col-md-6">
                  <label>Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                    name="phone_no"
                    onChange={(e) => handleInput(e)}
                    defaultValue={authState.phone_no}
                  />
                </div> */}
              </div>
              <div className="form-group row">
                <div className="col-sm-4 col-sm-offset-2">
                  <Link
                    to="/profile"
                    className="btn btn-white btn-sm"
                    type="reset"
                  >
                    Cancel
                  </Link>
                  <button
                    className="btn btn-primary-button btn-sm ms-2"
                    type="submit"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
