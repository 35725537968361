import { Link } from "react-router-dom";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import { useState, useEffect } from "react";
import henceforthApi from "../utils/henceforthApi";
import { useParams } from "react-router-dom";
import { Spinner } from "../components/BootstrapCompo";

const StaffDetails = () => {
  const params = useParams();
  const [memberDetail, setMemberDetail] = useState(false);
  const [userData, setUserData] = useState(memberDetail);

  useEffect(async () => {
    console.log("reloaded");
    const apiRes = await henceforthApi.Administrator.getStaffMemberDetail(
      params.id
    );
    console.log("apiresobnse", apiRes);
    setMemberDetail(apiRes.data);
  }, [userData]);

  const blockStaffMember = async () => {
    const payload = {
      _id: params.id,
      is_blocked: !memberDetail.is_blocked,
      is_deleted: memberDetail.is_deleted,
      language: "ENGLISH",
    };

    try {
      const response = await henceforthApi.Administrator.blockOrDeleteStaff(
        payload
      );
      setUserData(response.data);
      // setMemberDetail(response.data)
      console.log("User data set", userData);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteStaffMember = async () => {
    const payload = {
      _id: params.id,
      is_blocked: memberDetail.is_blocked,
      is_deleted: true,
      language: "ENGLISH",
    };
    try {
      const response = await henceforthApi.Administrator.blockOrDeleteStaff(
        payload
      );
      setUserData(response.data);
      console.log("User data set", userData);
      // setMemberDetail(response.data)
    } catch (err) {
      console.log(err);
    }
    window.history.back();
  };

  if (!memberDetail) {
    return <Spinner />;
  }

  return (
    <div className="row animated fadeInRight">
      {/* <div className="text-end">
        <div className="d-flex">

       
        <Link to={`/staff/edit/${params.id}`} className="btn  btn-primary-button me-2 back-btn">
          <i className="fas fa-edit me-1"></i>
          Edit
        </Link>
        <Link to={`/staff/edit/${params.id}`} className="btn  btn-primary-button me-2 back-btn">
          <i className="fas fa-edit me-1"></i>
          Edit
        </Link>
        </div>
      </div> */}

      <div className="ibox-title">
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/staffs/1/all" className="btn btn-primary-button me-3 px-4">
            <i class="fas fa-arrow-left me-1"></i>Back
          </Link>
          <div className="text-end">
            <Link
              className="btn btn-primary-button  me-3 px-4"
              to={`/staff/edit/${params.id}`}
            >
              <i class="fas fa-edit me-1"></i>Edit
            </Link>
          </div>
        </div>
      </div>

      <div className="ibox-content">
        <div className="col-md-4">
          <div className="ibox">
            <div className="ibox-title">
              <h5>Staff Profile Detail</h5>
            </div>
            <div className="ibox-content">
              <img
                src={
                  memberDetail.image
                    ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${memberDetail.image}`
                    : profile_placeholder
                }
                alt="ProfileImage"
                className="large-profile-img"
              />
              <div className="mt-4">
                <h4>{memberDetail.name}</h4>
                <p>
                  {" "}
                  Roles :{" "}
                  {memberDetail.roles.map((res, index) => {
                    return `${res}${
                      memberDetail.roles.length - 1 == index ? "" : ", "
                    }`;
                  })}
                </p>
                <p> Email : {memberDetail.email}</p>
              </div>

              <div className="mt-4">
                <button
                  onClick={blockStaffMember}
                  type="button"
                  className="btn-default btn btn-sm me-2"
                >
                  <i
                    className={
                      memberDetail.is_blocked == false
                        ? `fas fa-unlock me-2`
                        : `fas fa-lock me-2`
                    }
                  ></i>
                  {memberDetail.is_blocked == false ? `Block` : `Unblock`}
                </button>

                <button
                  disabled={memberDetail.is_deleted ? true : false}
                  onClick={deleteStaffMember}
                  type="button"
                  className="btn-outline-primary btn btn-sm me-2"
                >
                  <i
                    className={
                      memberDetail.is_deleted
                        ? `fa fa-ban me-2`
                        : "fas fa-trash-alt me-2"
                    }
                  ></i>{" "}
                  {memberDetail.is_deleted ? `Deleted` : `Delete`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffDetails;
