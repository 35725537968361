import Swal from "sweetalert2";
import { Link, useMatch } from "react-router-dom";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Profile from "../assets/images/profile_placeholder.png";
import { Spinner } from "../components/BootstrapCompo";
import axios from "axios";
const ReactQuill =
  typeof window === "object" ? require("react-quill") : () => false;

export default () => {
  const match = useMatch("/policies/:type/edit");
  const { authState, authDispatch } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);
  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState(false);
  const { type } = useParams();

  const [state, setState] = useState({
    created_at: "",
    image_url: "",
    title: "",
    type: "",
    updated_at: "",
    _id: "",
  });
  const [description, setDesciption] = useState("");
  const [title, setTitle] = useState("");

  const handleUpload = async (file) => {
    setLoading(true);
    const url = `${henceforthApi.API_ROOT}Upload`;
    const formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        token: authState.access_token,
      },
    };

    const apiRes = await (await axios.post(url, formData, config)).data;

    setFile(apiRes.data.file_name);
    setState({
      ...state,
      image_url: apiRes.data.file_name,
    });
    const data = {
      type: match.params.type,
      image_url: apiRes.data.file_name,
      description: description,
      language: "ENGLISH",
    };

    henceforthApi.Policies.addEditPolicy(data)
      .then((res) => {
        setLoading(false);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    console.log(file, "file");
    e.preventDefault();
    const data = {
      type: match.params.type,
      // image_url: state.image_url,
      description: description,
      title: title,
      language: "ENGLISH",
    };
    if (state.image_url) {
      data["image_url"] = state.image_url;
    }

    henceforthApi.Policies.addEditPolicy(data)
      .then((res) => {
        setLoading(false);
        console.log(res);
        Swal.fire({
          title: "content updated",
          text: "content updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        });

        window.history.back();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        Swal.fire({
          title: "Error!",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
  };
  const initialiseData = async () => {
    try {
      let apiRes = await henceforthApi.Policies.byType(match.params.type);
      let obj =
        Array.isArray(apiRes.data.data) && apiRes.data.data.length
          ? apiRes.data.data[0]
          : apiRes.data.data;
      setState({
        ...state,
        ...obj,
      });
      setDesciption(obj.description);
      setTitle(obj.title);
    } catch (error) {}
  };

  useEffect(() => {
    initialiseData();
  }, []);

  const onEditorStateChange = (e) => {
    console.log(e);
    setDesciption(e);
  };

  return (
    <div className="animated fadeInRight row">
      <div className="col-md-12">
        <div className="ibox">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="ibox-content spinerr-wrapper">
              <div className="form-group">
                <label>
                  <h4>Page Title:</h4>
                </label>
                <input
                  value={title}
                  type="text"
                  placeholder="Enter Page Title"
                  className="form-control"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>
                  <h4>Description:</h4>
                </label>
                <div
                  style={{
                    border: "1px solid rgba(0,0,0,.2)",
                    paddingBottom: "20px",
                    background: "white",
                  }}
                >
                  <ReactQuill
                    theme="snow"
                    onChange={(e) => onEditorStateChange(e)}
                    placeholder={`Write your content`}
                    value={description}
                  />
                </div>
              </div>

              <div className="form-group  spinner-inner">
                

                {loading ? (
                  <div class="position-relative d-inline-block">
                    <img
                      class="profile-user position-relative"
                      alt=""
                      src={
                        state.image_url
                          ? `${henceforthApi.API_FILE_ROOT_SMALL}${state.image_url}`
                          : Profile
                      }
                    />
                    <div
                      class={`spinner-border spinner-grow-md spinerrrrr   `}
                      role="status"
                    >
                      <span class="visually-hidden"></span>
                    </div>
                    <label class="label edit-label" for="upload-profile">
                      <i class="fa fa-camera"></i>
                    </label>
                    <input
                      onChange={(e) => handleUpload(e.target.files[0])}
                      type="file"
                      id="upload-profile"
                      class="form-control d-none"
                    />
                  </div>
                ) : (
                  <div class="position-relative d-inline-block">
                    <img
                      class="profile-user"
                      alt=""
                      src={
                        state.image_url
                          ? `${henceforthApi.API_FILE_ROOT_SMALL}${state.image_url}`
                          : Profile
                      }
                    />
                    <label class="label edit-label" for="upload-profile">
                      <i class="fa fa-camera"></i>
                    </label>
                    <input
                      onChange={(e) => handleUpload(e.target.files[0])}
                      type="file"
                      id="upload-profile"
                      class="form-control d-none"
                    />
                  </div>
                )}
              </div>
              <div className="form-group text-end">
                <button
                  type="button"
                  class="btn btn-white btn-sm"
                  onClick={() => window.history.back()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  class="btn btn-primary-button btn-sm ms-2"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
