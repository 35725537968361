
import React, { useContext, useState } from "react";
import Sidebar from "../components/dashboardLayout/sidebar/SideBar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "../layout/Dashboard";
import PoliciesPage from "../layout/PoliciesPage";
import PoliciesDetailsPage from "../layout/PoliciesDetailsPage";
import PoliciesEditPage from "../layout/PoliciesEditPage";
import ContactUsPage from "../layout/ContactUsPage";
import Notification from "../layout/Notification";
import Header from "../components/dashboardLayout/header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/dashboardLayout/breadcrumb";
import Profile from "../layout/profile";
import EmailTemplates from "../layout/emailTemplates";
import AddTemplates from "../layout/addTemplate";
import ChangePassword from "./changePassword";
import EditProfile from "../layout/editProfile";
import StaffManagement from "../layout/StaffManagement";
import StaffDetails from "../layout/staffDetails";
import EditStaff from "../layout/editStaff";
import UsersPage from "../layout/UsersPage";
import UserDetails from "../layout/UserDetails";
import NftDetailsPage from "../layout/NftDetailsPage";
import Language from "../layout/language";
import EditLanguage from "../layout/editLanguage";
import DbBackup from "../layout/dbBackup";
import AddStaff from "../layout/addStaff";
import EditTemplates from "../layout/editTemplates";
import FaqPage from "../layout/FaqPage";
import FaqEditPage from "../layout/FaqEditPage";
import AddFaq from "../layout/addFaq";
import CreatorDetails from "../layout/CreatorDetails";
import Creators from "../layout/Creators";
import NftsPage from "../layout/NftsPage";
import SalesPage from "../layout/SalesPage";
import PoliciesAddPage from "../layout/PoliciesAddPage";
import { GlobalContext } from "../context/Provider";
import { ROLES_DASHBOARD } from "../constants/actionTypes";
import NftsReportsPage from "../layout/NftsReportsPage";
import UsersReportsPage from "../layout/UsersReportsPage";
import TiktokApproval from "../layout/TiktokApproval";
import WeeklySectionEdit from "../layout/weekly/WeeklySectionEdit";
import WeeklySection from "../layout/weekly/WeeklySection";

function DashboardLayout() {
  const { authState, loading } = useContext(GlobalContext);
  const roles = (Array.isArray(authState.roles) ? authState.roles : [])

  let location = useLocation();

  const [isVisible, setIsVisible] = useState(false);
  const toggle = () => setIsVisible(!isVisible);

  return (
    <div id="wrapper">
      <Sidebar isVisible={isVisible} toggle={toggle} />
      <div id="page-wrapper" class="gray-bg">
        <Header toggle={toggle} />
        {location.pathname !== "/" ? <Breadcrumb {...location} /> : authState.super_admin ? '' : <Breadcrumb {...location} />}
        <div className="wrapper wrapper-content">
          <Routes>
            {roles.includes(ROLES_DASHBOARD) || authState.super_admin ?
              <Route path="/" element={<Dashboard />} /> :
              <Route path="/" element={<Profile />} />}
            <Route path="/policies" element={<PoliciesPage />} />
            <Route path="/policies/add" element={<PoliciesAddPage />} />
            <Route path="/policies/:type" element={<PoliciesDetailsPage />} />
            <Route path="/policies/:type/edit" element={<PoliciesEditPage />} />
            <Route path="/contact-us/:page" element={<ContactUsPage />} />
            <Route path="/reports/nft/:page" element={<NftsReportsPage />} />
            <Route path="/reports/users/:page" element={<UsersReportsPage />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/profile/edit" element={<EditProfile />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/staffs/:page/:role" element={<StaffManagement />} />
            <Route path="/staff/:id" element={<StaffDetails />} />
            <Route path="/staff/edit/:id" element={<EditStaff />} />
            <Route path="/staff/add" element={<AddStaff />} />
            <Route path="/email-template" element={<EmailTemplates />} />
            <Route path="/add-template" element={<AddTemplates />} />
            <Route path="/edit-template" element={<EditTemplates />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/orders/:page" element={<SalesPage />} />
            <Route path="/users/:page" element={<UsersPage />} />
            <Route path="/user/:id" element={<UserDetails />} />
            <Route path="/products/:page" element={<NftsPage />} />
            <Route path="/product/:id" element={<NftDetailsPage />} />
            <Route path="/faq/:page" element={<FaqPage />} />
            <Route path="/faq/edit/:id" element={<FaqEditPage />} />
            <Route path="/faq/add" element={<AddFaq />} />
            <Route path="/language" element={<Language />} />
            <Route path="/weekly-section/:type/:page" element={<WeeklySection />} />
            <Route path="/weekly-section/:type/edit/:page" element={<WeeklySectionEdit />} />
            <Route path="/language/edit/:id" element={<EditLanguage />} />
            <Route path="/creators/:page" element={<Creators />} />
            <Route path="/creator/:id" element={<CreatorDetails />} />
            <Route path="/db-backup" element={<DbBackup />} />
            <Route path="/tiktok-approval/:page" element={<TiktokApproval />} />
          </Routes>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default DashboardLayout;

