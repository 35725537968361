import { Link, useLocation, useMatch } from "react-router-dom";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import moment from "moment";
import { MarketDetailsPlayer } from "../components/ExploreComp";
import { TIMED_AUCTION } from "../constants/actionTypes";
import Swal from "sweetalert2";
export default () => {
  var countdownTimer;

  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);

  const match = useMatch("/product/:id");
  const {
    currentUsd,
    scrollToTop,
  } = useContext(GlobalContext);
  const [media_url, setMediaUrl] = useState("");
  const [timerCount, setTimerCount] = useState({
    days: "0",
    hours: "0",
    minutes: "0",
    seconds: "0",
  });
  const [reports, setReports] = useState({
    data: [],
    total_count: 0,
  });
  const [imageUrl, setImageUrl] = useState("");
  const [tradingHistory, setTradingHistory] = useState([]);
  const [state, setState] = useState({
    description: "",
    end_date: "",
    file_url: "",
    file_ext: "",
    video_url: "",
    hex_price: "",
    name: "",
    min_bid: "",
    min_bid_coin: "",
    royalties: "",
    start_date: "",
    created_at: "",
    tags: [],
    creator_address: "",
    type: "",
    owner_address: "",
    price: "",
    product_id: "",
    unlockable: "",
    created_by: {
      profile_pic: "",
      first_name: "",
      last_name: "",
      _id: "",
    },
    is_blocked: false,
    is_deleted: true,
    is_sold: true,
    bids_placed_by: [],
    auction_id: "",
    bid_id: null,
    coin: "BNB",
    current_owner: "",
    is_sold: false,
    order_id: "",
    trading_hstory: [],
    graph_data: {
      dataset: [],
      labels: [],
    },
  });

  const initialiseInterval = (end_date) => {
    if (end_date) {
      console.log("end_date", new Date(Number(end_date)));
      var _second = 1000;
      var _minute = _second * 60;
      var _hour = _minute * 60;
      var _day = _hour * 24;
      var end = new Date(Number(end_date));
      function timer() {
        var now = new Date();
        var distance = end - now;
        if (distance < 0) {
          clearInterval(countdownTimer);
          console.log("initialiseInterval EXPIRED!");
          return;
        }

        var days = Math.floor(distance / _day);
        var hours = Math.floor((distance % _day) / _hour);
        var minutes = Math.floor((distance % _hour) / _minute);
        var seconds = Math.floor((distance % _minute) / _second);
        setTimerCount({
          ...timerCount,
          days,
          hours,
          minutes,
          seconds,
        });
        // console.log("days", days, "hours", hours, "minutes", minutes, "seconds", seconds);
      }
      countdownTimer = setInterval(() => timer(), 1000);
    }
  };
  const recoveryNft = async () => {
    let items = {
      is_blocked: true,
      _id: match.params.id,
      language: "ENGLISH",
    };
    try {
      let apiRes = await henceforthApi.Nfts.recovery(items);
      await initialiseNftDetail();

      console.log("apiRes", apiRes);
      Swal.fire("Published!", "Nft visible to anyone.", "success");
    } catch (error) { }
  };
  const deleteNft = async () => {
    let items = {
      _id: match.params.id,
      language: "ENGLISH",
    };
    Swal.fire({
      title: "Are you sure?",
      text: "No one can access after this.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Unpublish it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let apiRes = await henceforthApi.Nfts.delete(items);
          await initialiseNftDetail();

          console.log("apiRes", apiRes);
          Swal.fire("Unpublished!", "Nft not visible to anyone.", "success");
        } catch (error) { }
      }
    });
  };
  const initialiseReportList = async () => {
    try {
      let apiRes = await henceforthApi.Nfts.reports(match.params.id);

      setReports(apiRes.data);
    } catch (error) { }
  };
  const initialiseTradingHistory = async () => {
    try {
      let apiRes = await henceforthApi.Nfts.tradingHistorynft(match.params.id);

      setTradingHistory(apiRes.data);
    } catch (error) { }
  };

  const initialiseNftDetail = async () => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let apiRes = await henceforthApi.Nfts.details(
      match.params.id,
      searchParam.get("type"),
      timezone
    );
    initialiseInterval(apiRes.data.end_date);
    console.log("apiRes", apiRes);
    let media_url = await apiRes.data.file_url;
    setMediaUrl(media_url);
    setState({
      ...state,
      ...apiRes.data,
      media_url,
    });
  };

  useEffect(() => {
    initialiseNftDetail();
  }, [searchParam.get("type")]);

  useEffect(() => {
    scrollToTop();
    initialiseReportList();
    initialiseTradingHistory();
  }, []);

 
  return (
    <div className="animated fadeInRight">
      <div className="ibox">
        <div className="ibox-content">
          <div className="row">
            <div>
              <button
                onClick={() => {window.history.back()}}
                class="btn-primary-button btn mr-2  back-btn"
              >
                <i class="fa fa-arrow-left me-1"></i>Back
              </button>
            </div>
            <div className="col-md-5">
              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <MarketDetailsPlayer
                      file_ext={state.file_ext}
                      file_url={state.file_url}
                      media_url={media_url}
                      setImageUrl={setImageUrl}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="mt-lg-0 mt-3">
                <div className="trade-right">
                  <div className="d-flex justify-content-between">
                    <div className="trade-heading">
                      <h3 className="mt-0">{state.name}</h3>
                      <h6>
                        Minted on{" "}
                        {moment(Number(state.created_at)).format(
                          "MMM DD, YYYY"
                        )}{" "}
                        by{" "}
                        <Link to={`/user/${state.created_by?._id}`}>
                          <strong>@{state.created_by?.user_name}</strong>
                        </Link>
                      </h6>
                    </div>
                  </div>
                  <div className="content mb-3">
                    <h3 className="fw-bold mt-3 mb-0">Description:</h3>
                    <p>{state.description}</p>
                  </div>
                  <hr />
                  <div className="ammount-text color-changes d-flex align-items-center mb-2">
                    <h5 >Tags : </h5>
                    <p className="ms-2 mb-0 text-capitalize">
                      {state.tags?.length ? state.tags + "" : "No tags"}
                    </p>
                  </div>
                  <div className="ammount-text color-changes d-flex align-items-center mb-2">
                    <h5>Royalty :</h5>
                    <p className="ms-2 mb-0 text-capitalize">
                      {state.royalties}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <a
                      className="font-size-link website-link"
                      href={`https://staging.everlens.io/token/${state.token_id}/${state._id}/bids/last_30_days`}
                      target="_blank"
                    >
                      Link to website
                    </a>






                   
                  </div>
                  <hr />
                  <div className="owned-div">
                    <div className="d-flex mt-4 align-items-center">
                     
                      <div className="text-img d-flex align-items-center">
                        <div className="owned-img">
                          <img
                            src={
                              state.created_by && state.created_by?.profile_pic
                                ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${state.created_by?.profile_pic}`
                                : profile_placeholder
                            }
                          />
                        </div>
                        <div className="owned-text ms-3">
                          <h4 className="my-0">Created By</h4>
                          <h3 className=" my-0 fw-bold">

                            {state.created_by?.name
                              ? `${state.created_by?.name} `
                              : ""}{" "}

                          </h3>
                          <p className="text-gray mb-0">

                            {state.created_by?.user_name
                              ? `${state.created_by?.user_name} `
                              : ""}{" "}

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4 className="my-3 ">
                    <strong>{state.type}</strong>
                  </h4>

                  {state.type ? (
                    state.type && state.type == TIMED_AUCTION ? (
                      <div className="current-bid">
                        <div className="d-flex justify-content-between">
                          <div className="text-img d-flex">
                            <div className="ammount-text">
                              <h4>Current Bid</h4>
                              <h3>
                                <strong>
                                  {state.latest_bid
                                    ? state.latest_bid.bid_amount
                                    : state.min_bid}{" "}
                                  ELEN
                                </strong>
                              </h3>
                              <h5>
                                $
                                {Number(
                                  Number(
                                    state.latest_bid
                                      ? state.latest_bid.bid_amount
                                      : state.min_bid
                                  ) * currentUsd
                                ).toFixed(4)}
                              </h5>
                            </div>
                          </div>
                          <div className="text-img d-flex">
                            <div className="ammount-text color-changes">
                              <h4>Starting Bid</h4>
                              <h3>
                                <strong>{state.min_bid} ELEN</strong>
                              </h3>
                              <h5>
                                $
                                {Number(
                                  Number(state.min_bid) * currentUsd
                                ).toFixed(4)}
                              </h5>
                            </div>
                          </div>
                        </div>

                        <div className="auction-end mt-4 pt-4 mb-3 pb-3">
                          {moment(Number(state.end_date)).utc().valueOf() >
                            moment().utc().valueOf() ? (
                            <>
                              <h2>Auction ends in</h2>
                              <div className="times-full d-flex">
                                <div className="times">
                                  <h4>
                                    {String(timerCount.days).length == 1
                                      ? `0${timerCount.days}`
                                      : timerCount.days}
                                  </h4>
                                  <h6>Days</h6>
                                </div>
                                <div className="times">
                                  <h4>
                                    {String(timerCount.hours).length == 1
                                      ? `0${timerCount.hours}`
                                      : timerCount.hours}
                                  </h4>
                                  <h6>Hours</h6>
                                </div>
                                <strong>:</strong>
                                <div className="times">
                                  <h4>
                                    {String(timerCount.minutes).length == 1
                                      ? `0${timerCount.minutes}`
                                      : timerCount.minutes}
                                  </h4>
                                  <h6>Minutes</h6>
                                </div>
                                <strong>:</strong>
                                <div className="times">
                                  <h4>
                                    {String(timerCount.seconds).length == 1
                                      ? `0${timerCount.seconds}`
                                      : timerCount.seconds}
                                  </h4>
                                  <h6>Seconds</h6>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="d-flex justify-content-between">
                              <h2>
                                Auction ended{" "}
                                {moment(Number(state.end_date)).format(
                                  "MMM DD, YYYY hh:mm:ss a"
                                )}
                              </h2>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-img d-flex">
                          <div className="ammount-text">
                            {/* <h4>Buy It Now</h4> */}
                            <h4 className="mb-1">
                              <strong>{state.price} ELEN</strong>
                            </h4>
                            <h5>
                              $
                              {Number(Number(state.price) * currentUsd).toFixed(
                                2
                              )}
                            </h5>
                          </div>
                        </div>

                        
                      </div>
                    )
                  ) : (
                    ""
                  )}

                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {state.bids_placed_by.length ? (
        <div className="row">
          <div className="col-md-12">
            <div className="ibox animated fadeInRight">
              <div className="ibox-title">
                <div className="title-head">
                  <h5>Bids place by</h5>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">From</th>
                        <th scope="col">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(state.bids_placed_by) &&
                        state.bids_placed_by.map((res) => {
                          return (
                            <tr key={res._id}>
                              <td>
                                {moment(Number(res.created_at)).format(
                                  "MMM DD, YYYY"
                                )}{" "}
                                at{" "}
                                {moment(Number(res.created_at)).format(
                                  "hh:mma"
                                )}
                              </td>
                              <td>@{res.bid_placed_by.user_name}</td>
                              <td>{res.bid_amount} ELEN</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="view-more mb-2">
                    <div className="d-flex align-items-center justify-content-center">
                      {/* <button to="/" className="btn btn-xs btn-white me-2">
                    View More
                  </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="row">
        <div className="col-md-12">
          <div className="ibox animated fadeInRight">
            <div className="ibox-title">
              <div className="title-head mb-0">
                <h5>Trading History </h5>
              </div>
            </div>
            <div className="ibox-content">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">From</th>
                      <th scope="col">To</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(tradingHistory.trading_history) &&
                      tradingHistory.trading_history.map((res) => {
                        console.log(res)
                        return (
                          <tr key={res._id}>
                            <td>
                              {moment(Number(res.created_at)).format(
                                "MMM DD, YYYY"
                              )}{" "}
                              at{" "}
                              {moment(Number(res.created_at)).format("hh:mma")}
                            </td>
                            <td style={{ color: "#EA53B4;" }}>
                              <Link
                                to={`/${res.sold_by?.account_type == "CREATER"
                                  ? "creator"
                                  : "user"
                                  }/${res.sold_by?._id}`}
                              >
                                <span>@{res.sold_by?.user_name}</span>
                              </Link>
                            </td>
                            <td style={{ color: "#49AADB;" }}>
                              <Link
                                to={`/${res.sold_to?.account_type == "CREATER"
                                  ? "creator"
                                  : "user"
                                  }/${res.sold_to?._id}`}
                              >
                                <span>@{res.sold_to?.user_name}</span>
                              </Link>
                            </td>
                            <td style={{ color: "#4471C3;" }}>
                              {res.price} ELEN
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="view-more mb-2">
                  <div className="d-flex align-items-center justify-content-center">
                    {/* <button to="/" className="btn btn-xs btn-white me-2">
                    View More
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-md-12">
          <NFTPriceBarChar
            {...state.graph_data}
            created_at={state.created_at}
            create_time_price={state.create_time_price}
            title="Price History"
          />
        </div>
      </div> */}

      {reports.total_count ? (
        <div className="row">
          <div className="col-md-12">
            <div className="ibox animated fadeInRight">
              <div className="ibox-title">
                <div className="title-head mb-0">
                  <h5>Reports</h5>
                </div>
              </div>
              <div className="ibox-content">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Sr no</th>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Report Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reports.data.map((res, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              <img
                                src={
                                  res.reported_by.profile_pic
                                    ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.reported_by.profile_pic}`
                                    : profile_placeholder
                                }
                                className="me-2 imageSize"
                                alt=""
                              />
                              {res.reported_by.first_name}{" "}
                              {res.reported_by.last_name}
                            </td>
                            <td>
                              {moment(Number(res.created_at)).format(
                                "MMM DD, YYYY"
                              )}
                            </td>
                            <td>{res.report_reason}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* <div className="view-more mb-2">
                <div className="d-flex align-items-center justify-content-center">
                  <button to="/" className="btn btn-xs btn-white me-2">
                    View More
                  </button>
                </div>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        class="modal image-modal images-modals-open fade"
        id="openPhotosModal"
        tabindex="-1"
        aria-labelledby="openPhotosModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <img src={imageUrl} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
