import { Link, useMatch } from "react-router-dom";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import verifiedImg from "../assets/images/verified.svg";
import { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import { ExploreCard } from "../components/ExploreComp";
import { WALLET_SLICE_WALLET } from "../constants/actionTypes";

const CreatorDetails = () => {
  const match = useMatch("/creator/:id");
  const { authState, currentUsd, scrollToTop } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);

  const [state, setState] = useState({
    account_status: "",
    account_type: "",
    admin_verified: false,
    age_verified: false,
    bio: "",
    cover_img: null,
    created_at: "",
    device_type: null,
    email: "",
    email_verified: false,
    fcm_token: null,
    name: "",
    is_blocked: false,
    is_deleted: false,
    last_name: "",
    last_seen: "",
    profile_pic: "",
    social_token: "",
    social_type: "",
    total_coins: 0,
    updated_at: "",
    user_name: "",
    user_status: 0,
    _id: "",
  });
  const [privatePhotos, setPrivatePhotos] = useState({
    data: [],
    total_count: 0,
  });
  const [publicPhotos, setPublicPhotos] = useState({
    data: [],
    total_count: 0,
  });
  const [socialNetwork, setSocialNetwork] = useState([]);
  const [createdNfts, setCreatedNfts] = useState({
    data: [],
    total_count: 0,
  });
  const [ownNfts, setOwnNfts] = useState({
    data: [],
    total_count: 0,
  });
  const [soldNfts, setSoldNfts] = useState({
    data: [],
    total_count: 0,
  });
  const [tips, setTips] = useState({
    data: [],
    total_count: 0,
  });

  const [manageUsers, setManageUsers] = useState({
    type: "",
    _id: "",
    is_blocked: false,
    is_deleted: false,
    admin_verified: false,
    language: "ENGLISH",
    account_status: "",
  });

  const [modalImageUrl, setModalImageUrl] = useState("");

  const { id } = useParams();

  const initialiseCreatedNfts = async () => {
    let apiRes = await henceforthApi.Creator.getCreatedNfts(match.params.id, 0);
    console.log("initialiseCreatedNfts", apiRes.data);
    setCreatedNfts(apiRes.data);
  };
  const initialiseOwnNfts = async () => {
    if (!ownNfts.total_count) {
      let apiRes = await henceforthApi.Creator.getOwnNfts(match.params.id, 0);
      console.log("initialiseOwnNfts", apiRes.data);
      setOwnNfts(apiRes.data);
    }
  };
  const initialiseSoldNfts = async () => {
    if (!soldNfts.total_count) {
      let apiRes = await henceforthApi.Creator.getSoldNfts(match.params.id, 0);
      console.log("initialiseOwnNfts", apiRes.data);
      setSoldNfts(apiRes.data);
    }
  };
  const initialiseSocialNetwork = async () => {
    let apiRes = await henceforthApi.Creator.getMediaList(match.params.id);
    console.log("media", apiRes.data);
    setSocialNetwork(apiRes.data);
  };

  const initialiseTips = async () => {
    try {
      let apiRes = await henceforthApi.Creator.getTips(match.params.id, 0);
      console.log("initialiseTips res", apiRes);
      setTips(apiRes.data);
      await initialiseSocialNetwork();
      // await initialisePublicPhotos();
      // await initalisePrivatePhotos();
      // await initalisePrivatePhotos();
      await initialiseCreatedNfts();
    } catch (error) {}
  };

  const initialiseDetails = async () => {
    const creatorDetail = await henceforthApi.Creator.getCreatorDetails(
      match.params.id
    );
    console.log("user details are", creatorDetail);
    setState(creatorDetail.data);
  };

  // const initalisePrivatePhotos = async () => {
  //   const prPhotos = await henceforthApi.Creator.getPhotos(match.params.id, 'PRIVATE', 0)
  //   // console.log("private photos are", prPhotos)
  //   setPrivatePhotos(prPhotos.data)
  // }

  // const initialisePublicPhotos = async () => {
  //   const pbPhotos = await henceforthApi.Creator.getPhotos(match.params.id, 'PUBLIC', 0)
  //   // console.log("public photos are", pbPhotos)
  //   setPublicPhotos(pbPhotos.data)
  // }

  // const handleActivate = async () => {

  //   let data = {
  //     type: "ACTIVATE/DEACTIVATE",
  //     _id: state._id,
  //     language: "ENGLISH",
  //     account_status: state.account_status == 'ACTIVATED' ? `DEACTIVATED` : `ACTIVATED`,
  //   }

  //   try {
  //     const manage_users = await henceforthApi.Creator.manageCreators(data)

  //     setManageUsers(data)
  //   } catch (error) {
  //     console.log(error, "error is ");
  //     Swal.fire({
  //       title: 'Error!',
  //       text: error.response.body.error_description,
  //       icon: 'error',
  //       confirmButtonText: 'Cancel'
  //     })

  //   }
  // }
  // const handleVerify = async (status) => {
  //   console.log(state._id, match.params.id);
  //   try {
  //     let apiRes = await henceforthApi.Creator.verifyProfile(match.params.id, status)
  //     console.log(apiRes);
  //     setState({
  //       ...state,
  //       ...apiRes.data
  //     })
  //   } catch (error) {

  //   }
  // }

  // const handleBlocking = async () => {

  //   let data = {
  //     type: "BLOCK/DELETE",
  //     _id: state._id,
  //     is_blocked: !state.is_blocked,
  //     is_deleted: state.is_deleted,
  //     language: "ENGLISH",
  //     account_status: state.account_status
  //   }

  //   try {
  //     const manage_users = await henceforthApi.Creator.manageCreators(data)
  //     console.log("mmanage users", manage_users)
  //     setManageUsers(manage_users)
  //   } catch (error) {
  //     console.log(error, "error is ")
  //     Swal.fire({
  //       title: 'Error!',
  //       text: error,
  //       icon: 'error',
  //       confirmButtonText: 'Cancel'
  //     })
  //   }
  // }

  // const handleDelete = async () => {
  //   let data = {
  //     type: "BLOCK/DELETE",
  //     _id: state._id,
  //     is_deleted: true,
  //     language: "ENGLISH",
  //   }
  //   Swal.fire({
  //     title: "Are you sure delete this creator?",
  //     showCancelButton: true,
  //     confirmButtonText: "Delete now!",
  //     cancelButtonText: "No"
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         const manage_users = await henceforthApi.Creator.manageCreators(data)
  //         Swal.fire({
  //           title: 'Creator Deleted',
  //           text: 'Creator deleted  successfully',
  //           icon: 'success',
  //           confirmButtonText: 'OK'
  //         })
  //         console.log("mmanage users", manage_users)
  //         setManageUsers(manage_users);

  //       } catch (err) {
  //         Swal.fire({
  //           title: 'Error!',
  //           text: err.response.body.error_description,
  //           icon: 'error',
  //           confirmButtonText: 'Cancel'
  //         })
  //       }
  //     }
  //   })
  // }

  useEffect(() => {
    scrollToTop();
    initialiseDetails(id);
    console.log("creator  details updated");
  }, [manageUsers]);

  useEffect(() => {
    initialiseTips();
  }, []);

  return (
    <>
      <div className="row animated fadeInRight">
        <div className="col-md-6">
          <div className="ibox">
            <div className="ibox-title">
              <h5>Nfts Details</h5>
            </div>
            <div className="ibox-content">
              <div className="text-center">
                <img
                  src={
                    socialNetwork.file_url
                      ? `${socialNetwork.file_url}`
                      : profile_placeholder
                  }
                  alt="ProfileImage"
                  className="detail-img"
                />
                {/* {console.log(socialNetwork.created_by.name)} */}
              </div>
              <div className="mt-4">
                <h3 className="text-black mb-0 ">
                  Name :
                  <span className="text-muted-userD">
                    {socialNetwork.name
                      ? ` ${socialNetwork.name} `
                      : `Not Available`}
                  </span>
                </h3>
                <h3 className="text-black mb-0 ">
                  Nft Type:
                  <span className="text-muted-userD">
                    {socialNetwork.nft_type
                      ? socialNetwork.nft_type
                      : "Not Available"}
                  </span>{" "}
                </h3>
                <h3 className="text-black mb-0 ">
                  Description:{" "}
                  <span className="text-muted-userD">
                    {socialNetwork.description
                      ? socialNetwork.description
                      : "Not Available"}
                  </span>
                </h3>
                <h3 className="text-black mb-0 ">
                  Price:
                  <span className="text-muted-userD">
                    {socialNetwork.price ? socialNetwork.price : "NA"}
                  </span>{" "}
                </h3>
                <h3 className="text-black mb-0 ">
                  No of Copies:
                  <span className="text-muted-userD">
                    {socialNetwork.total_copies
                      ? socialNetwork.total_copies
                      : "NA"}
                  </span>{" "}
                </h3>
                <hr />
                <h3 className="text-black mb-0 ">
                  Royalties:
                  <span className="text-muted-userD">
                    {socialNetwork.royalties ? socialNetwork.royalties : "NA"}
                  </span>{" "}
                </h3>
                <hr />
                <h3 className="text-black mb-0 ">
                  Tags:{" "}
                  <span className="text-muted-userD">
                    {socialNetwork.tags ? socialNetwork.tags : "NA"}
                  </span>
                </h3>
                <hr />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-8">
        <div className="ibox animated fadeInRight">
          <div className="ibox-title">
            <div className="title-head">
              <h5>Tip Listing </h5>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Sr no</th>
                    <th>User Name</th>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {tips.data.map((res, index) => {
                    return <tr>
                      <td>{index + 1}</td>
                      <td>
                        <img src={res.sent_by.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.sent_by.profile_pic}` : profile_placeholder} className="me-2 imageSize" alt="" />
                        {res.sent_by.display_name} {res.sent_by.last_name}
                      </td>
                      <td>{res.amount} BNB</td>
                      <td>{moment(Number(res.created_at)).format("MMM DD, YYYY")}</td>
                    </tr>
                  })}
                </tbody>
              </table>
              {tips.total_count > 10 ?
                <div className="view-more mb-2">
                  <div className="d-flex align-items-center justify-content-center">
                    <Link to={`/creator/${id}/tips`}><button className="btn btn-xs btn-white me-2">
                      View More
                    </button></Link>
                  </div>
                </div> : ''}
            </div>
          </div>
        </div>
      </div> */}
      </div>

      {/* <div className="row">
      <div className="col-md-12">
        <div className="ibox animated fadeInRight">
          <div className="ibox-title">
            <div className="title-head">
              <h5>Reports</h5>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Sr no</th>
                    <th>Creator Name</th>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <img src={icon} className="me-2 imageSize" alt="" />
                      Creator User
                    </td>
                    <td>50$</td>
                    <td>30-02-2021</td>
                  </tr>
                </tbody>
              </table>
              <div className="view-more mb-2">
                <div className="d-flex align-items-center justify-content-center">
                  <button className="btn btn-xs btn-white me-2">
                    View More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    </>
  );
};

export default CreatorDetails;
