import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Graphs from "../Graphs";
import henceforthApi from "../../utils/henceforthApi";



export default () => {
  const [priceHistory, setPriceHistory] = useState({
    data: []
  })
  const location = useLocation()
  const navigate = useNavigate()
  const searchParam = new URLSearchParams(location.search)
  const changeGraphType = (graph_type) => {
    const newParam = new URLSearchParams()
    if (graph_type) {
      newParam.set("graph_type", graph_type)
    }
    navigate({ search: newParam.toString() })
  }
  

  const initialisePriceHistory = async () => {
    try {
      let apiRes = await henceforthApi.Dashboard.getTradingPriceById(searchParam.get("graph_type"))
      console.log('apiRes', apiRes);
      setPriceHistory({
        ...priceHistory,
        ...apiRes.data
      })
    } catch (error) {

    }
  }
  useEffect(() => {
    initialisePriceHistory()
  }, [searchParam.get("graph_type")]);
console.log(searchParam.get("graph_type"))
  return (
    <div className="ibox">
      <div className="ibox-title d-flex justify-content-between">
        <h5>Time vs Sales</h5>
        <div className="ibox-tools">
         
          <div className="downn-arrow position-relative text-end">
            <i className="far fa-angle-down position-absolute "></i>
            <select className="trant-btn btn btn-light Create-btn rounded-pill pe-4 ps-4 " name="month" onChange={(e) => changeGraphType(String(e.target.value).toLowerCase())} value={String(searchParam.get("graph_type")).toUpperCase()}>

              <option value="LAST_7_DAYS">Last 7 days</option>
              <option value="LAST_14_DAYS">Last 14 days</option>
              <option value="LAST_30_DAYS">Last 30 days</option>
              <option value="LAST_60_DAYS">Last 60 days</option>
              <option value="LAST_90_DAYS">Last 90 days</option>
              <option value="LAST_YEAR">Last year</option>
              <option value="ALL_TIME">All time</option>
            </select>
          </div>
        </div>
      </div>
      <div className="ibox-content">
        <div className="row">
          <div className="col-md-6 offset-md-3">
          <div className="chartjs w-100 d-flex justify-content-center">
            {<Graphs.LineChart {...priceHistory} />}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}