export const ERROR_UNAUTHORIZED = "UNAUTHORIZED";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const AUTH_AGE_VERIFY = "AUTH_AGE_VERIFY";
export const INITIALISE_PROFILE = "INITIALISE_PROFILE";

export const STATIC_DATA_SUCCESS = "STATIC_DATA_SUCCESS";
export const STATIC_DATA_DASHBOARD = "STATIC_DATA_DASHBOARD";
export const STATIC_DATA_NFTS = "STATIC_DATA_NFTS";

export const PAGE_LIMIT_SUCCESS = "PAGE_LIMIT_SUCCESS";
export const FIXED_PRICE = "FIXED_PRICE";
export const TIMED_AUCTION = "TIMED_AUCTION";
export const RIGHT_AFTER_LISTING = "RIGHT_AFTER_LISTING";
export const PICK_SPECIFIC_DATE = "PICK_SPECIFIC_DATE";
export const EXPIRE_1_DAY = "EXPIRE_1_DAY";
export const EXPIRE_3_DAY = "EXPIRE_3_DAY";
export const EXPIRE_5_DAY = "EXPIRE_5_DAY";
export const EXPLORE_ADD = "EXPLORE_ADD";
export const EXPLORE_UPDATE = "EXPLORE_UPDATE";


export const FIXED_PRICE_AUCTION_ID = "0x01";
export const TIMED_AUCTION_AUCTION_ID = "0x02";

export const NFT_STEP_0_INIT = 0;
export const NFT_STEP_1_UPLOAD = 1;
export const NFT_STEP_2_MINT = 2;
export const NFT_STEP_3_APPROVE = 3;
export const NFT_STEP_4_PUT_ON_SALE = 4;
export const NFT_STEP_5_VERIFY_SIGNATURE = 5;

export const WALLET_SLICE_WALLET = 5;

export const CHAT_STATUS = "CHAT_STATUS";
export const SOCIAL_TYPE_PROVIDER = "PROVIDER";
export const SOCIAL_TYPE_META_MASK = "META_MASK";
export const SOCIAL_TYPE_WALLET_CONNECT = "WALLET_CONNECT";
// NFT TYPES
export const NFT_OWN = "own";
export const NFT_SOLD = "sold";
export const NFT_RE_SALE = "resale";
// ROLES TYPES
export const ROLES_STAFF_MEMBERS = "STAFF_MEMBERS";
export const ROLES_DASHBOARD = "DASHBOARD";
export const ROLES_USERS = "USERS";
export const ROLES_CREATOR = "CREATOR";
export const ROLES_NFTS = "NFTS";
export const ROLES_SALES = "SALES";
export const ROLES_FAQS = "FAQS";
export const ROLES_POLICIES = "POLICIES";
export const ROLES_CONTACT_US = "CONTACT_US";
export const ROLES_REPORTS = "REPORTS";
export const ROLES_NOTIFICATION = "NOTIFICATION";
export const ROLES_BACKUP = "BACKUP";

// NFT_FILTER
export const SOLD = "SOLD";
export const ON_SALE = "SALE";
export const ALL = "ALL";

// social network
export const NFT_INSTAGRAM = "https://www.instagram.com/nfty.it";
export const NFT_TWITTER = "https://www.twitter.com/nftyit";
export const NFT_MEDIUM = "https://medium.com/NFTYIt";
export const NFT_DISCORD = "https://www.instagram.com/nfty.it";
export const NFT_TIK_TOK = "https://www.instagram.com/nfty.it";
export const NFT_REDDIT = "https://www.reddit.com/user/NFTY_It/";
