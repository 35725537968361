import { Spinner } from "./BootstrapCompo";
import DataNotAvailable from "./DataNotAvailable";

export default ({ count, data, page, limit, loading, onPageChange }) => {
  const visited_page_size = (limit * (page - 1)) + data.length
  return <div class="d-flex justify-content-center mt-4">
    {loading ? <Spinner /> : count > limit ?
      <nav aria-label="...">
        <ul class="pagination">
          <li class="page-item btn-round" onClick={() => (page !== 1) ? onPageChange(page - 1) : ""}>
            <button disabled={(page == 1)} class={`btn "btn-outline-primary`}><i class="fas fa-chevron-left"></i><span class="d-none d-md-block ms-2"></span></button>
          </li>

          {((page - 2) > 0) ?
            <li class="page-item" onClick={() => onPageChange(page - 2)}><span class="page-link">{page - 2}</span></li>
            : <></>}

          {((page - 1) > 0) ?
            <li class="page-item" onClick={() => onPageChange(page - 1)}><span class="page-link">{page - 1}</span></li>
            : <></>}

          <li class="page-item active" aria-current="page">
            <span class="page-link">{page}</span>
          </li>

          {(visited_page_size < count) ?
            <li class="page-item" onClick={() => onPageChange(page + 1)}><span class="page-link">{page + 1}</span></li>
            : <></>}

          {((visited_page_size + limit) < count) ?
            <li class="page-item" onClick={() => onPageChange(page + 2)}><span class="page-link">{page + 2}</span></li>
            : <></>}

          <li class="page-item" onClick={() => (visited_page_size < count) ? onPageChange(page + 1) : ""}>
            <button disabled={!(visited_page_size < count)} class={`btn "btn-outline-primary `}><span class="d-none d-md-block me-2"></span><i class="fas fa-chevron-right" ></i></button>
          </li>


        </ul>
      </nav> : count == 0 ?
        <DataNotAvailable /> : ''}
  </div>
}


