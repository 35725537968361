import { Link } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Profile from "../assets/images/profile_placeholder.png";
import Swal from "sweetalert2";
import { Spinner } from "../components/BootstrapCompo";
import { toast } from "react-toastify";

const EditStaff = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { authState, staffMembers } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [memberDetail, setMemberDetail] = useState(false);
  const [state, setState] = useState({
    selected: new Map(),
  });

  useEffect(async () => {
    const apiRes = await henceforthApi.Administrator.getStaffMemberDetail(
      params.id
    );
    console.log("apiresobnse", apiRes);
    let data = apiRes.data;
    setMemberDetail(data);
    if (Array.isArray(data.roles)) {
      let newMap = new Map();
      data.roles.map((res) => {
        newMap.set(res, res);
      });
      setState({
        ...state,
        selected: newMap,
      });
    }
  }, []);

  const handleFile = async (file) => {
    setLoader(true);
    const url = `${henceforthApi.API_ROOT}Upload`;
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        token: authState.access_token,
        language: "ENGLISH",
      },
    };

    const apiRes = await (await axios.post(url, formData, config)).data;

    setMemberDetail({
      ...memberDetail,
      image: apiRes.data.file_name,
    });
    console.log(apiRes, "upload image");
    setLoader(false);
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setMemberDetail({
      ...memberDetail,
      [name]: value,
    });
  };

  const handleCancel = () => {
    // navigate(`/staff-details/${params.id}`);
    window.history.back();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let rowData = [];

    state.selected.forEach((element) => {
      rowData.push(element);
    });

    if (!rowData.length) {
      return toast.warn("Please select min 1 role");
    }

    console.log(setMemberDetail, "me");

    let items = {
      _id: id,
      name: memberDetail.name,
      email: memberDetail.email,
      password: memberDetail.password,
      language: "ENGLISH",
    };
    if (memberDetail.image) {
      items["image"] = memberDetail.image;
    }

    delete items.password;

    henceforthApi.Administrator.addStaffMembers({ ...items, roles: rowData })
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: "Profile updated",
          text: "Profile updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        window.history.back();
      })
      .catch((err) => {
        console.log(err.response.body);
        Swal.fire({
          title: "Error!",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
  };

  const addRoleList = (role, checked) => {
    const { selected } = state;
    if (checked) {
      selected.set(role, role);
    } else {
      selected.delete(role);
    }
    setState({
      ...state,
      selected,
    });
  };

  return (
    <div class="row animated fadeInRight">
      <div class="col-md-12">
        <div class="ibox">
          <div class="ibox-title p-3 d-flex space-between">
            {/* <Link to={`/staffs/edit`} className="btn btn-primary-button me-3 px-4">
                <i class="fas fa-arrow-left me-1"></i>Back
              </Link> */}
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h4>Edit Staff</h4>
              </div>
              <div class="col-auto">
                {/* <Link to="/staff-details/12" class="btn-primary-button btn mr-2 back-btn">
                  <i class="fas fa-trash me-1"></i>Delete
                </Link> */}
              </div>
            </div>
          </div>
          <div class="ibox-content spinerr-wrapper">
            <div class="text-center my-4 spinner-inner">
           
              {loader ? (
                <div class="position-relative d-inline-block">
                  <img
                    class="profile-user position-relative"
                    alt=""
                    src={
                      memberDetail.image
                        ? `${henceforthApi.API_FILE_ROOT_SMALL}${memberDetail.image}`
                        : Profile
                    }
                  />
                  <div
                    class={`spinner-border spinner-grow-md spinerrrrr   `}
                    role="status"
                  >
                    <span class="visually-hidden"></span>
                  </div>
                  <label class="label edit-label" for="upload-profile">
                    <i class="fa fa-camera"></i>
                  </label>
                  <input
                    onChange={(e) => handleFile(e.target.files[0])}
                    type="file"
                    id="upload-profile"
                    class="form-control d-none"
                  />
                </div>
              ) : (
                <div class="position-relative d-inline-block">
                  <img
                    class="profile-user"
                    alt=""
                    src={
                      memberDetail.image
                        ? `${henceforthApi.API_FILE_ROOT_SMALL}${memberDetail.image}`
                        : Profile
                    }
                  />
                  <label class="label edit-label" for="upload-profile">
                    <i class="fa fa-camera"></i>
                  </label>
                  <input
                    onChange={(e) => handleFile(e.target.files[0])}
                    type="file"
                    id="upload-profile"
                    class="form-control d-none"
                  />
                </div>
              )}
            </div>
            <form onSubmit={handleSubmit}>
              <div class="form-row row">
                <div class="form-group col-md-6">
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control"
                    pattern="^[^-\s][a-zA-Z0-9_\s-]+$"
                    title="Please enter a valid name"
                    required=""
                    placeholder="Name"
                    maxlength="30"
                    name="name"
                    onChange={handleChange}
                    value={memberDetail.name}
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Email</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Enter Email"
                    name="email"
                    onChange={handleChange}
                    value={memberDetail.email}
                  />
                </div>

                <div className="row">
                  {staffMembers.map((res) => {
                    return (
                      <span className="col-md-2">
                        <input
                          type={`checkbox`}
                          className=" me-1"
                          value="fw"
                          checked={state.selected.has(res)}
                          onChange={(e) => addRoleList(res, e.target.checked)}
                        />
                        {res}{" "}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div class="form-group row mt-3">
                <div class="col-sm-4 col-sm-offset-2">
                  <button
                    onClick={handleCancel}
                    class="btn btn-white btn-sm"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    class="btn btn-primary-button btn-sm ms-2"
                    type="submit"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStaff;
