import { useNavigate } from "react-router-dom";
import { useState, useContext } from 'react';
import { GlobalContext } from "../../context/Provider";
import henceforthApi from "../../utils/henceforthApi";
import Swal from 'sweetalert2';
import { toast } from "react-toastify";


function ChangePassword() {


  const navigate = useNavigate();
  const [state, setState] = useState({
    oldPass: '',
    newPass: '',
    confirmPass: '',
    language: 'ENGLISH'
  })


  const { authState, authDispatch } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);


  const handleChange = (e) => {

    console.log(e)
    const name = e.target.name;
    const value = e.target.value;

    setState({
      ...state,
      [name]: value
    })
  }

  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.newPass === state.confirmPass) {
      henceforthApi.Administrator.changePassword(state)
        .then(res => {
          console.log(res, "resposne changepassd")
          Swal.fire({
            title: 'Changed Password',
            text: 'Password Changed  successfully',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((isConfirm) => {
            if (isConfirm) {
              navigate('/profile')
            }
          })
        })
        .catch(err => {
          console.log(err, "error")
          Swal.fire({
            title: 'Error!',
            text: err.response.body.error_description,
            icon: 'error',
            confirmButtonText: 'Cancel'
          })
        })
    } else {
      toast.warn("Password not match, Please try again.")
    }
  }
  const [firstEye, setFirstEye] = useState(true);
  const [secondEye, setSecondEye] = useState(true);
  const [thirdEye, setThirdEye] = useState(true);

  return (
    <div class="animated fadeInDown">
      <div class="row">
        <div class="col-md-12">
          <div class="ibox-content mb-4">
            <div class="row">
              <div class="col-lg-12">
                <form onSubmit={handleSubmit} class="m-t" role="form">
                  <div class="form-group position-relative">
                    <input
                      onChange={handleChange}

                      type={firstEye ? "password" : "text"}
                      class="form-control"
                      placeholder="Enter old password"
                      name="oldPass"
                      value={state.oldPass}
                      required
                    />
                    <i
                class={`fas  eye-btn eye-wrapper position-absolute ${firstEye ? `fa-eye-slash` : `fa-eye`}`}
                onClick={() => setFirstEye(!firstEye)}
                aria-hidden="true"
              ></i>
                  </div>

                  <div class="form-group  position-relative">
                    <input
                      name="newPass"
                      onChange={handleChange}
                      value={state.newPass}
                      type={secondEye ? "password" : "text"}
                      class="form-control"
                      placeholder="Enter new password"
                      required
                    />
                      <i
                class={`fas  eye-btn fas eye-wrapper  position-absolute ${secondEye ? `fa-eye-slash` : `fa-eye` }`}
                onClick={() => setSecondEye(!secondEye)}
                aria-hidden="true"
              ></i>
                  </div>


                  <div class="form-group position-relative">
                    <input
                      name="confirmPass"
                      onChange={handleChange}
                      value={state.confirmPass}
                      type={thirdEye ? "password" : "text"}
                      class="form-control"
                      placeholder="Enter confirm password"
                      required
                    />
                      <i
                class={`fas  eye-btn eye-wrapper position-absolute ${thirdEye ? `fa-eye-slash` : `fa-eye`}`}
                onClick={() => setThirdEye(!thirdEye)}
                aria-hidden="true"
              ></i>
                    
                  </div>




                   


                  <div className="form-group">
                    <button onClick={() => navigate('/profile')} type="button" class="btn btn-white me-2">
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-primary-button me-2">
                      Change Password
                    </button>
                  </div>


                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
