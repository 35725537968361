import { useState } from "react";
import henceforthApi from "../utils/henceforthApi";
import Swal from "sweetalert2";
import { GlobalContext } from "../context/Provider";
import { useContext } from "react";
const ReactQuill =
  typeof window === "object" ? require("react-quill") : () => false;

export default () => {
  const { authState } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);


  let [faq, setFaq] = useState({
    title: "",
    content: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFaq({
      ...faq,
      [name]: value,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      question: faq.title,
      answer: faq.content,
      language: "ENGLISH",
    };
    console.log("data", data);
    try {
      const response = await henceforthApi.Faq.addFaq(data);
      Swal.fire({
        title: "Faq Added",
        text: "Faq added successfully",
        icon: "success",
        confirmButtonText: "OK",
      });
      window.history.back();
    } catch (err) {
      console.log("error is ", err);
      Swal.fire({
        title: "Failed!",
        text: err.response.body.error_description,
        icon: "error",
        confirmButtonText: "Cancel",
      });
    }
  };

  return (
    <div class="row animated fadeInRight">
      <div class="col-md-12">
        <div class="ibox">
          <div class="ibox-title p-3">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h4>Add Faq</h4>
              </div>
              <div class="col-auto">
                {/* <Link to="/profile" class="btn-primary-button btn back-btn">
                  <i class="fas fa-trash me-1"></i>Delete
                </Link> */}
              </div>
            </div>
          </div>
          <div class="ibox-content">
            <form onSubmit={handleSubmit}>
              <div class="form-row row">
                <div class="form-group col-md-12">
                  <label>Title</label>
                  <input
                    pattern="^[^-\s][a-zA-Z0-9_\s-]+$"
                    title="Please enter a valid title"
                    required=""
                    type="text"
                    className="form-control"
                    name="title"
                    value={faq.title}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div class="form-group col-md-12">
                  <label>Content</label>

                  <ReactQuill
                    theme="snow"
                    onChange={(e) => setFaq({ ...faq, content: e })}
                    placeholder={`Write your content`}
                    value={faq.content}
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-4 col-sm-offset-2">
                  <button
                    class="btn btn-primary-button btn-sm ms-2"
                    type="submit"
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
