import Profile from "../assets/images/profile_placeholder.png";
import henceforthApi from "../utils/henceforthApi";
import { useState, useContext } from "react";
import axios from "axios";
import { GlobalContext } from "../context/Provider";
import { Spinner } from "../components/BootstrapCompo";
import { toast } from "react-toastify";



let roleListInMap = new Map();
const AddStaff = () => {
  const { authState, staffMembers } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);


  const [firstEyes, setFirstEyes] = useState(true);
  const [loader, setLoader] = useState(false);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    language: "ENGLISH",
    roles: "",
  });


  const handleFile = async (file) => {
    setLoader(true);
    const url = `${henceforthApi.API_ROOT}Upload`;
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        token: authState.access_token,
      },
    };



    const apiRes = await (await axios.post(url, formData, config)).data;
    console.log(apiRes, "upload image");
    setLoader(false);
    setNewUser({
      ...newUser,
      image: apiRes.data.file_name,
    });
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setNewUser({
      ...newUser,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form values are", newUser);
    let rowData = [];
    roleListInMap.forEach((element) => {
      rowData.push(element);
    });
    if (!newUser.name.trim()) {
      return toast.warn("Please provide name");
    }
    if (!newUser.email.trim()) {
      return toast.warn("Please provide email");
    }
    if (!rowData.length) {
      return toast.warn("Please select min 1 role");
    }
    henceforthApi.Administrator.addStaffMembers({ ...newUser, roles: rowData })
      .then((res) => {
        console.log(res);
        setNewUser({
          name: "",
          image: "",
          email: "",
          password: "",
          language: "ENGLISH",
          roles: "",
        });
        window.history.back();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.body.error_description);
      });
  };


  const addRoleList = (role, checked) => {
    if (checked) {
      roleListInMap.set(role, role);
    } else {
      roleListInMap.delete(role);
    }
  };


  return (
    <div class="row animated fadeInRight">
      <div class="col-md-12">
        <div class="ibox">
          <div class="ibox-title p-3">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h4>Add Staff</h4>
              </div>
              {/* <div class="col-auto">
                <Link to="/staff-details/12" class="btn-primary-button btn mr-2 back-btn">
                  <i class="fas fa-trash me-1"></i>Delete
                </Link>
              </div> */}
            </div>
          </div>
          <div class="ibox-content">
            <div class="text-center my-4">
              {loader ? (
                <Spinner />
              ) : (
                <div class="position-relative d-inline-block">
                  <img
                    class="profile-user"
                    alt=""
                    src={
                      newUser.image
                        ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${newUser.image}`
                        : Profile
                    }
                  />
                  <label class="label edit-label" for="upload-profile">
                    <i class="fa fa-camera"></i>
                  </label>
                  <input
                    onChange={(e) => handleFile(e.target.files[0])}
                    type="file"
                    id="upload-profile"
                    class="form-control d-none"
                  />
                </div>
              )}
            </div>
            <form onSubmit={handleSubmit}>
              <div class="form-row row">
                <div class="form-group col-md-6">
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Name"
                    maxlength="30"
                    name="name"
                    onChange={handleChange}
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Email</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Enter Email"
                    maxlength="50"
                    name="email"
                    onChange={handleChange}
                  />
                </div>

                <div class="form-group col-md-6 eyes-on">
                  <label>Password</label>
                  <input
                    type={firstEyes ? "password" : "text"}
                    class="form-control"
                    placeholder="Password"
                    maxlength="30"
                    name="password"
                    onChange={handleChange}
                  />
                  <i
                    class={`fas  eye-btn ${firstEyes ? `fa-eye-slash` : `fa-eye`
                      }`}
                    onClick={() => setFirstEyes(!firstEyes)}
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="row">
                  {staffMembers.map((res) => {
                    return (
                      <span className="col-md-2">
                        <input
                          type={`checkbox`}
                          className=" me-1"
                          value="fw"
                          onChange={(e) => addRoleList(res, e.target.checked)}
                        />
                        {res}{" "}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div class="form-group row mt-2">
                <div class="col-sm-4 col-sm-offset-2">
                  {/* <button class="btn btn-white btn-sm" type="button">
                <div class="mt-2">
                  <button class="btn btn-white btn-sm" type="button">
                    Cancel
                  </button> */}
                  <button
                    class="btn btn-primary-button btn-sm ms-2"
                    type="submit"
                    disabled={!newUser.email}
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStaff;
