import { Link, useMatch } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";

export default () => {
  const { authState, authDispatch } = useContext(GlobalContext);

  const [state, setState] = useState({
    data: [],
    total_count: 0,
  });
  henceforthApi.setToken(authState.access_token);

  const initialiseData = async () => {
    try {
      let apiRes = await henceforthApi.Policies.all();
      setState({
        ...state,
        ...apiRes.data,
      });
      console.log(apiRes);
    } catch (error) {}
  };
  useEffect(() => {
    initialiseData();
  }, []);

  return (
    <div className="ibox animated fadeInRight">
      <div className="ibox-title">
        <h5>Policies</h5>
        {/* <div className="d-flex justify-content-end">
        <Link to={`/policies/add`} class="btn btn-sm btn-primary-button h-100" >
          Add
        </Link>
      </div> */}
      </div>
      <div className="ibox-content ">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr</th>
                <th>Title</th>
                <th>Content</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {state.data.map((res, index) => {
                return (
                  <tr key={res._id}>
                    <td>{index + 1}</td>
                    <td>{res.title}</td>
                    <td
                      className="word-break"
                      dangerouslySetInnerHTML={{
                        __html:
                          res.description.length > 300
                            ? res.description.slice(0, 300) + "..."
                            : res.description,
                      }}
                    ></td>

                    <td>
                      <div className="text-center">
                        <Link
                          to={`/policies/${res.type}`}
                          className="btn-white btn btn-xs me-2 "
                        >
                          <i className="fas fa-eye me-1"></i>View
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
