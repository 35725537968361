import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EmailVariables from "../components/emailVariables";
import PreviewModal from "../components/previewModal";

const EditTemplates = () => {
  return (
    <>
      <div className="row animated fadeInRight">
        <div className="col-md-8 col-xxl-9">
          <div className="back-btn">
            <button
              type="button"
              class="btn btn-primary-button me-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              data-bs-whatever="@getbootstrap"
            >
              Preview
            </button>
            <button className="btn btn-white">Delete</button>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <h3>Subject</h3>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-md-12">
              <h3>Email Body</h3>
              <div className="ibox-content mb-3">
                <h3 className="mb-3">
                  <span>Hey</span>
                  <button className="key-btn ms-2 px-3 py-2">first name</button>
                </h3>

                <p>Thanks for registration!</p>
                <p class="mb-4">Your login credentials:</p>
                <h3>
                  <label className="me-2">Login:</label>
                  <button className="key-btn px-3 py-2">email</button>
                </h3>
                <h3>
                  <label className="me-2">Password:</label>
                  <button className="key-btn px-3 py-2">password</button>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xxl-3 mb-3">
          <EmailVariables />
        </div>
      </div>
      <PreviewModal />
    </>
  );
};

export default EditTemplates;
