import { Link, useLocation, useNavigate } from "react-router-dom";
import ProfileImage from "../assets/images/profile_placeholder.png";
import { useState, useEffect, useContext } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import { ERROR_UNAUTHORIZED } from "../constants/actionTypes";
import { toast } from "react-toastify";
import staticDataDashboardSuccess from "../context/actions/auth/staticDataDashboardSuccess";

const Profile = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const { authState, staticDataDispatch, logOutNow } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);
  console.log("adminData is", authState);

  const initialiseProfile = async () => {
    try {
      const dbData = await henceforthApi.Dashboard.getPagnation();
      staticDataDashboardSuccess(dbData.data)(staticDataDispatch);

    } catch (error) {
      if (error?.response?.body?.error == ERROR_UNAUTHORIZED) {
        logOutNow()
      } else {
        toast.error(error.response.body.error_description)
      }
    }
  }
  useEffect(initialiseProfile, [])

  return (
    <div className="row animated fadeInRight">
      <div className="text-end">
        <Link to="/profile/edit" className="btn btn-primary-button back-btn">
          <i className="fas fa-edit me-1"></i>Edit
        </Link>
      </div>
      <div className="col-md-4">
        <div className="ibox">
          <div className="ibox-title">
            <h5>{authState.super_admin ? 'Admin' : 'Staff'} Profile Detail</h5>
          </div>
          <div className="ibox-content">
            <img
              src={
                authState.image
                  ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${authState.image}`
                  : ProfileImage
              }
              alt="ProfileImage"
              class="large-profile-img"
            />
            <div className="mt-4">
              <h4>{authState.name.charAt(0).toUpperCase() + authState.name.slice(1)}</h4>
              <p>
                <i className="fas fa-envelope me-2"></i>
                <span>{authState.email}</span>
              </p>

              <div className="mt-3 btn-group">
                <Link
                  to="/change-password"
                  className="btn btn-primary-button me-2 btn-sm"
                >
                  <i class="fas fa-key me-1"></i>Change Password
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
